import { Box } from '@mui/material';
import styles from './styles.module.scss';
import WorldPromptInput from './worldPromptInput';
import FloatingBackButton from '../FloatingBackButton';

const WorldPrompt=({})=>{
  return (
    <Box className={styles.WorldPrompt}>
      <FloatingBackButton />
      <WorldPromptInput />
    </Box> 
  )
}

export default WorldPrompt