import { useNavigate, useParams } from 'react-router-dom';
import WorldViewTemplate from '../Templates/WorldViewTemplate';
import styles from './styles.module.scss';
import { validate as uuidValidate, version as uuidVersion} from 'uuid'
import useGlobalState from '../../Stores/globalStateManager';
import { useCallback, useEffect } from 'react';
import { getWorld, getWorldDetails } from '../../customQueries/customQueries';
import VistaView from '../../Components/VistaView';
import WorldMenu from '../../Components/WorldMenu';

const WorldView=({children})=>{
  const {worldGuidParam} = useParams();
  const navTo = useNavigate();
  const {localWorld,setLocalWorld,currentVistaIdx,setCurrentVistaIdx} = useGlobalState((state)=>({localWorld:state.localWorld,setLocalWorld:state.setLocalWorld,currentVistaIdx:state.currentVistaIdx,setCurrentVistaIdx:state.setCurrentVistaIdx}))
  // console.log('WorldView worldGuidParam:',worldGuidParam);
  // console.log('WorldView localWorld:',localWorld)
  const navHome = useCallback(()=>{
    navTo('/');
  },[])

  useEffect(()=>{
    // check if worldGuid is valid
    // check if worldGuid matches uuidv4 format
    try {
      if (worldGuidParam.indexOf('BW') === -1 && !uuidValidate(worldGuidParam) && !uuidVersion(worldGuidParam) === 4) {
        navHome();
        return;
      }
    }
    catch(err){
      navHome();
      return;
    }
    
    // check if localWorld.id === worldGuidParam
    if (worldGuidParam === localWorld?.id) {
      // do nothing, let renderer render localWorld
      // console.log('worldGuidParam === localWorld.id localWorld is',localWorld)
      return;
    }
    else {
      // if not do gql query to find worldGuidParam
      const checkNewWorldGuid = async ()=>{
        const worldDetails = await getWorldDetails(worldGuidParam);
        console.log('new world details retreived:',worldDetails)
        if (!worldDetails?.id) { 
          // TODO - more robust world not found behavior
          // if worldGuid is not found, prog nav to home
          setCurrentVistaIdx(0);
          navHome();
          return;
        }
        else {
          // console.log('new worldDetails pulled from be:',worldDetails)
          setCurrentVistaIdx(0);
          
          setLocalWorld(worldDetails);// set localWorld to returned world from worldGuid query
        }
      }
      
      checkNewWorldGuid();
    }
    
  },[worldGuidParam,localWorld])
  
  useEffect(()=>{
    console.log('worldView fresh render')
  },[])


  return (
    <WorldViewTemplate>
      <VistaView tex={localWorld?.vistas?.items[currentVistaIdx || 0]?.url}/>
    </WorldViewTemplate>
  )
}

export default WorldView