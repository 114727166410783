import { Box, Button, Typography } from '@mui/material';
import styles from './styles.module.scss';
import stardustPhials from '../../../../assets/img/StardustPhials.webp'
import SelectionOption from './selectionOption';
import { useState } from 'react';
const SelectStardustModal=({})=>{
  const [selectedOptionIdx,setSelectedOptionIdx] = useState(0) 
  return (
    <Box className={styles.SelectStardustModal}>
      <Box className={styles.inner}>
        <img src={stardustPhials} />
        <Typography variant="SelectStardustModalHeader" component="h2">How much Stardust would you like?</Typography>
        <Box className={styles.SelectionOptions}>
          <SelectionOption name="Scout" price="5.00" qty={100} idx={0} selectedOptionIdx={selectedOptionIdx} setSelectedOptionIdx={setSelectedOptionIdx} />
          <SelectionOption name="Pioneer" price="10.00" qty={200} idx={1} selectedOptionIdx={selectedOptionIdx} setSelectedOptionIdx={setSelectedOptionIdx} />
          <SelectionOption name="Master" price="25.00" qty={500} idx={2} selectedOptionIdx={selectedOptionIdx} setSelectedOptionIdx={setSelectedOptionIdx} />
        </Box>
        <Button variant="contained">Buy Stardust!</Button>
      </Box>
    </Box>
  )
}

export default SelectStardustModal