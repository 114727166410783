import { Box } from '@mui/material';
import styles from './styles.module.scss';
import {ReactComponent as WorldMenuIcon} from '../../assets/noun-world-1819964.svg';
import useGlobalState from '../../Stores/globalStateManager';
const WorldMenuButton=({})=>{
  const {worldMenuOpen,setWorldMenuOpen,toggleWorldMenuOpen} = useGlobalState((state)=>({worldMenuOpen:state.worldMenuOpen,setWorldMenuOpen:state.setWorldMenuOpen,toggleWorldMenuOpen:state.toggleWorldMenuOpen}))
  
  return (
    <Box className={styles.WorldMenuButton}>
      <WorldMenuIcon onClick={toggleWorldMenuOpen} />
    </Box>
  )
}

export default WorldMenuButton