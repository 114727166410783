import { Box } from '@mui/material';
import useGlobalState from '../../Stores/globalStateManager';
import styles from './styles.module.scss';
import {ReactComponent as TouchIcon} from '../../assets/icons/noun-touch-1152976.svg'
import {ReactComponent as EyeIcon} from '../../assets/icons/noun-eye-6605299.svg'
import {ReactComponent as CamIcon} from '../../assets/icons/noun-camera-6616923.svg'
import { useCallback, useState } from 'react';
const ControlTypePicker=({})=>{
  const {controlTypeSelection,setControlTypeSelection,isMotionAvailable,orientationPermsDenied} = useGlobalState((state)=>({controlTypeSelection:state.controlTypeSelection,setControlTypeSelection:state.setControlTypeSelection,isMotionAvailable:state.isMotionAvailable,orientationPermsDenied:state.orientationPermsDenied}))
  const [expanded,setExpanded] = useState(false);

  // const toggleExpanded = useCallback(()=>{
  //   setExpanded(!expanded)
  // },[expanded])

  const handleClick = useCallback((e)=>{
    const controlType = e.target.getAttribute('data-control-type');
    if (!expanded) {
      setExpanded(true);
      return;
    }
    else if (!!controlType) {
      setControlTypeSelection(controlType);
      setExpanded(false);
    }
    else {
      setExpanded(!expanded);
    }
    
  },[expanded])

  return ( 
    <Box className={expanded? styles.ControlTypePickerExpanded:styles.ControlTypePicker} onClick={handleClick}>
      {(!expanded && controlTypeSelection==="drag") && 
        <Box data-control-type="orientation">
          <TouchIcon style={{left:'-3px',width:'110%'}} data-control-type="drag" />
        </Box>
      }
      {(!expanded && controlTypeSelection==="orientation") && 
        <Box data-control-type="orientation">
          <EyeIcon data-control-type="orientation" />
        </Box>
      }
      {(!expanded && controlTypeSelection==="camera" && 
        <Box data-control-type="orientation">
          <CamIcon data-control-type="camera" />
        </Box>
      )}
      {!!expanded &&
        <>
          <TouchIcon style={{left:'-3px',width:'110%'}} data-control-type="drag" />
          {!!isMotionAvailable && <EyeIcon data-control-type="orientation" disabled={orientationPermsDenied} />}
          <CamIcon data-control-type="camera" />
        </>
      }
    </Box>
  )
}

export default ControlTypePicker