import { Box } from '@mui/material';
import styles from './styles.module.scss';

const FloatingSubheader=({children=null})=>{
  return (
    <Box className={styles.FloatingSubheader}>
      {children}
    </Box>
  )
}

export default FloatingSubheader