import { useEffect } from 'react';
import useAuthState from '../../Stores/authStateManager';
import styles from './styles.module.scss';

const AuthGate=({authedComponent = <></>, unAuthedComponent = <></>})=>{
  const {currentAuthenticatedUser} = useAuthState((state)=>({currentAuthenticatedUser:state.currentAuthenticatedUser}))

  useEffect(()=>{
    console.log('AuthGate currentAuthenticatedUser',currentAuthenticatedUser)
  },[currentAuthenticatedUser])

  return (
    <>
      {!!currentAuthenticatedUser &&
        authedComponent
      }
      {!currentAuthenticatedUser &&
        unAuthedComponent
      }
    </>
  )
}

export default AuthGate