import { Box, Modal } from '@mui/material';
import styles from './styles.module.scss';
import useGlobalState from '../../../Stores/globalStateManager';
import { useCallback, useEffect } from 'react';

const TerraformModal=({content})=>{
  const {terraformModals,setTerraformModals} = useGlobalState((state)=>({terraformModals:state.terraformModals,setTerraformModals:state.setTerraformModals}))

  return (
    <Modal
      open={true}
      onClose={()=>{}}
      slotProps={{
        backdrop:{
          sx: {
            background: 'rgba(0,0,0,0.7)'
          }
        }
      }}
    >
      <Box className={styles.TerraformModal}>
        <div>
          {terraformModals[0]}
        </div>
      </Box>
    </Modal>
  )
}

export default TerraformModal