import { Box, Paper, Typography } from '@mui/material';
import BasePageTemplate from '../Templates/BasePageTemplate';
import styles from './styles.module.scss';
import WorldPromptInput from '../../Components/WorldPrompt/worldPromptInput';
import WorldPrompt from '../../Components/WorldPrompt';
import FloatingHeader from '../../Components/FloatingHeader';
import FloatingBackButton from '../../Components/FloatingBackButton';

const CreatePage=({})=>{
  return (      
      <Box className={styles.CreatePage}>
        <FloatingHeader />
        <WorldPrompt />
      </Box>
  )
}

export default CreatePage