import { DeviceOrientationControls } from '@react-three/drei';
import styles from './styles.module.scss';
import CameraOverlay from '../CameraOverlay';

const OrientationCamera=({})=>{
  
  return (
    <>
      <DeviceOrientationControls />
    </>
  )
}

export default OrientationCamera