import { Box } from '@mui/material';
import styles from './styles.module.scss';
import {ReactComponent as HamburgerMenu} from '../../assets/icons/noun-hamburger-4238597-FFFFFF.svg'
import useGlobalState from '../../Stores/globalStateManager';
import { useCallback } from 'react';
const MenuButton=({})=>{ 
  const {mainMenuOpen,setMainMenuOpen} = useGlobalState((state)=>({mainMenuOpen:state.mainMenuOpen,setMainMenuOpen:state.setMainMenuOpen}))
  
  const toggleMenu = useCallback(()=>{
    setMainMenuOpen(!mainMenuOpen)
  },[mainMenuOpen])
  
  return (
    <Box className={(mainMenuOpen)? styles.MenuButtonOpen:styles.MenuButton} onClick={toggleMenu}>
      <HamburgerMenu />
    </Box>
  )
}

export default MenuButton