/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateVista = /* GraphQL */ `
  subscription OnCreateVista($filter: ModelSubscriptionVistaFilterInput) {
    onCreateVista(filter: $filter) {
      id
      name
      url
      prompt
      world {
        id
        name
        description
        rootUrl
        status
        createdBy
        worldType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      worldVistasId
      __typename
    }
  }
`;
export const onUpdateVista = /* GraphQL */ `
  subscription OnUpdateVista($filter: ModelSubscriptionVistaFilterInput) {
    onUpdateVista(filter: $filter) {
      id
      name
      url
      prompt
      world {
        id
        name
        description
        rootUrl
        status
        createdBy
        worldType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      worldVistasId
      __typename
    }
  }
`;
export const onDeleteVista = /* GraphQL */ `
  subscription OnDeleteVista($filter: ModelSubscriptionVistaFilterInput) {
    onDeleteVista(filter: $filter) {
      id
      name
      url
      prompt
      world {
        id
        name
        description
        rootUrl
        status
        createdBy
        worldType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      worldVistasId
      __typename
    }
  }
`;
export const onCreateWorldPhoto = /* GraphQL */ `
  subscription OnCreateWorldPhoto(
    $filter: ModelSubscriptionWorldPhotoFilterInput
  ) {
    onCreateWorldPhoto(filter: $filter) {
      id
      name
      url
      world {
        id
        name
        description
        rootUrl
        status
        createdBy
        worldType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      worldPhotosId
      __typename
    }
  }
`;
export const onUpdateWorldPhoto = /* GraphQL */ `
  subscription OnUpdateWorldPhoto(
    $filter: ModelSubscriptionWorldPhotoFilterInput
  ) {
    onUpdateWorldPhoto(filter: $filter) {
      id
      name
      url
      world {
        id
        name
        description
        rootUrl
        status
        createdBy
        worldType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      worldPhotosId
      __typename
    }
  }
`;
export const onDeleteWorldPhoto = /* GraphQL */ `
  subscription OnDeleteWorldPhoto(
    $filter: ModelSubscriptionWorldPhotoFilterInput
  ) {
    onDeleteWorldPhoto(filter: $filter) {
      id
      name
      url
      world {
        id
        name
        description
        rootUrl
        status
        createdBy
        worldType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      worldPhotosId
      __typename
    }
  }
`;
export const onCreateWorldSnippet = /* GraphQL */ `
  subscription OnCreateWorldSnippet(
    $filter: ModelSubscriptionWorldSnippetFilterInput
  ) {
    onCreateWorldSnippet(filter: $filter) {
      id
      content
      attribution
      world {
        id
        name
        description
        rootUrl
        status
        createdBy
        worldType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      worldSnippetsId
      __typename
    }
  }
`;
export const onUpdateWorldSnippet = /* GraphQL */ `
  subscription OnUpdateWorldSnippet(
    $filter: ModelSubscriptionWorldSnippetFilterInput
  ) {
    onUpdateWorldSnippet(filter: $filter) {
      id
      content
      attribution
      world {
        id
        name
        description
        rootUrl
        status
        createdBy
        worldType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      worldSnippetsId
      __typename
    }
  }
`;
export const onDeleteWorldSnippet = /* GraphQL */ `
  subscription OnDeleteWorldSnippet(
    $filter: ModelSubscriptionWorldSnippetFilterInput
  ) {
    onDeleteWorldSnippet(filter: $filter) {
      id
      content
      attribution
      world {
        id
        name
        description
        rootUrl
        status
        createdBy
        worldType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      worldSnippetsId
      __typename
    }
  }
`;
export const onCreateWorld = /* GraphQL */ `
  subscription OnCreateWorld($filter: ModelSubscriptionWorldFilterInput) {
    onCreateWorld(filter: $filter) {
      id
      name
      description
      vistas {
        nextToken
        __typename
      }
      photos {
        nextToken
        __typename
      }
      snippets {
        nextToken
        __typename
      }
      rootUrl
      status
      createdBy
      worldType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateWorld = /* GraphQL */ `
  subscription OnUpdateWorld($filter: ModelSubscriptionWorldFilterInput) {
    onUpdateWorld(filter: $filter) {
      id
      name
      description
      vistas {
        nextToken
        __typename
      }
      photos {
        nextToken
        __typename
      }
      snippets {
        nextToken
        __typename
      }
      rootUrl
      status
      createdBy
      worldType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteWorld = /* GraphQL */ `
  subscription OnDeleteWorld($filter: ModelSubscriptionWorldFilterInput) {
    onDeleteWorld(filter: $filter) {
      id
      name
      description
      vistas {
        nextToken
        __typename
      }
      photos {
        nextToken
        __typename
      }
      snippets {
        nextToken
        __typename
      }
      rootUrl
      status
      createdBy
      worldType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      preferred_username
      phone_number
      currencyStore {
        id
        qty
        createdAt
        updatedAt
        userCurrencyStoreUserId
        __typename
      }
      createdAt
      updatedAt
      userCurrencyStoreId
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      preferred_username
      phone_number
      currencyStore {
        id
        qty
        createdAt
        updatedAt
        userCurrencyStoreUserId
        __typename
      }
      createdAt
      updatedAt
      userCurrencyStoreId
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      preferred_username
      phone_number
      currencyStore {
        id
        qty
        createdAt
        updatedAt
        userCurrencyStoreUserId
        __typename
      }
      createdAt
      updatedAt
      userCurrencyStoreId
      __typename
    }
  }
`;
export const onCreateUserCurrencyStore = /* GraphQL */ `
  subscription OnCreateUserCurrencyStore(
    $filter: ModelSubscriptionUserCurrencyStoreFilterInput
  ) {
    onCreateUserCurrencyStore(filter: $filter) {
      id
      user {
        id
        preferred_username
        phone_number
        createdAt
        updatedAt
        userCurrencyStoreId
        __typename
      }
      qty
      createdAt
      updatedAt
      userCurrencyStoreUserId
      __typename
    }
  }
`;
export const onUpdateUserCurrencyStore = /* GraphQL */ `
  subscription OnUpdateUserCurrencyStore(
    $filter: ModelSubscriptionUserCurrencyStoreFilterInput
  ) {
    onUpdateUserCurrencyStore(filter: $filter) {
      id
      user {
        id
        preferred_username
        phone_number
        createdAt
        updatedAt
        userCurrencyStoreId
        __typename
      }
      qty
      createdAt
      updatedAt
      userCurrencyStoreUserId
      __typename
    }
  }
`;
export const onDeleteUserCurrencyStore = /* GraphQL */ `
  subscription OnDeleteUserCurrencyStore(
    $filter: ModelSubscriptionUserCurrencyStoreFilterInput
  ) {
    onDeleteUserCurrencyStore(filter: $filter) {
      id
      user {
        id
        preferred_username
        phone_number
        createdAt
        updatedAt
        userCurrencyStoreId
        __typename
      }
      qty
      createdAt
      updatedAt
      userCurrencyStoreUserId
      __typename
    }
  }
`;
export const onUpdateWorldStatus = /* GraphQL */ `
  subscription OnUpdateWorldStatus($world_id: ID!) {
    onUpdateWorldStatus(world_id: $world_id) {
      id
      name
      description
      vistas {
        nextToken
        __typename
      }
      photos {
        nextToken
        __typename
      }
      snippets {
        nextToken
        __typename
      }
      rootUrl
      status
      createdBy
      worldType
      createdAt
      updatedAt
      __typename
    }
  }
`;
