import { Box, Typography } from '@mui/material';
import styles from './styles.module.scss';
import defaultThumb from '../../assets/baseWorldThumbs/BW00-Mechanical-Forests.jpg'
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
const AuthedBaseWorldThumbnail=({thumb=defaultThumb,worldName="Unnamed World",worldGuid="BWOO-Mechanical-Forests",sx={}})=>{
  const navTo = useNavigate();
  const handleClick = useCallback(()=>{
    navTo(`${process.env.PUBLIC_URL}/worldView/${worldGuid}`)
  },[worldGuid])
  return (
    <Box className={styles.BaseWorldThumbnail} onClick={handleClick} sx={sx}>
      <Box className={styles.inner}>
        <img src={thumb} />
        <Typography variant="baseWorldThumbnail">{worldName}</Typography>
      </Box>
    </Box>
  )
}

export default AuthedBaseWorldThumbnail