import styles from './styles.module.scss';
import { getBaseWorlds } from '../../customQueries/customQueries';
import { useEffect, useState } from 'react';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { API } from 'aws-amplify';
import { getWorld } from '../../customQueries/customQueries'; // TODO DELME

const BaseWorldList=({})=>{
  const [baseWorlds,setBaseWorlds] = useState(null) 
  useEffect(()=>{
    const fetchBaseWorlds = async()=>{
      try {
        const _baseWorlds = await API.graphql({
          query:getBaseWorlds, 
          variables: {
            worldType: "base" // TODO fix this query so we don't have to pass base in as a var
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        })
        console.log('_baseWorlds',_baseWorlds)
        setBaseWorlds(_baseWorlds?.data?.worldsByWorldType?.items)
        // return _baseWorlds?.data?.listWorlds?.items;
      }
      catch(err){
        // TODO error handling
        // setTerraformModals([]);
        console.error("failed fetching base worlds:",err)
        return null;
      }
    }
    fetchBaseWorlds();
  },[])

  useEffect(()=>{
    if (!baseWorlds) return;
    console.log('baseWorlds state updated:',baseWorlds)
  },[baseWorlds])

  return (
    <>
      {!!baseWorlds?.length && baseWorlds.map((baseWorld,idx)=>{
        return <span key={baseWorld.id}></span>
      })}
    </>
  )
}

export default BaseWorldList