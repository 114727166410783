import useGlobalState from '../../Stores/globalStateManager';
import LoginFlowModal from '../Modals/LoginFlowModal';
import LoginTeaseModal from '../Modals/LoginTeaseModal';
import StandardModal from '../Modals/StandardModal';
import TerraformModal from '../Modals/TerraformModal';
import styles from './styles.module.scss';

const ModalResolver=({})=>{
  const {terraformModals,setTerraformModals,showLoginFlow,showLoginTease,standardModals,setStandardModals} = useGlobalState((state)=>({terraformModals:state.terraformModals,setTerraformModals:state.setTerraformModals,showLoginFlow:state.showLoginFlow,showLoginTease:state.showLoginTease,standardModals:state.standardModals,setStandardModals:state.setStandardModals}))
  if (!!terraformModals?.length && (!terraformModals[0]?.type)) {
    setTerraformModals([])
  }
  if (!!standardModals?.length && (!standardModals[0]?.type)) {
    setStandardModals([])
  }

  // console.log('!!(standardModals?.length) && !(showLoginFlow) && !(showLoginTease)',!!(standardModals?.length) && !(showLoginFlow) && !(showLoginTease))
  // console.log('standardModals.length',standardModals.length)
  return (
    <>
      {/* standard modals */}
      {!!(standardModals?.length) && !(showLoginFlow) && !(showLoginTease) &&
        <StandardModal />
      }
      {/* login tease */}
      {!(showLoginFlow) && !!(showLoginTease) && <LoginTeaseModal />}
      {/* login modal */}
      {!!(showLoginFlow) && <LoginFlowModal />}
      {/* terraform modals */}
      {!!terraformModals?.length && (!!terraformModals[0]?.type) &&
        <TerraformModal /> 
      }
    </>
  )
}

export default ModalResolver