import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import galaxy1 from '../../assets/skybox_galaxy_1_2048.jpeg'
import { useFrame, useLoader, useThree } from '@react-three/fiber';
import { EquirectangularRefractionMapping, TextureLoader,BackSide, DoubleSide,RepeatWrapping, CubeReflectionMapping, Mesh, PlaneGeometry, MeshBasicMaterial} from 'three';
import { degToRad } from 'three/src/math/MathUtils';
import gsap from 'gsap';
import { VRButton, useXR } from '@react-three/xr';
import { Billboard, CameraControls, Plane, DeviceOrientationControls } from '@react-three/drei';
import LoadSpinner3D from '../LoadSpinner3D';
import ConditionalControls from '../ConditionalControls';
import useGlobalState from '../../Stores/globalStateManager';

const VistaView=({tex=galaxy1,size=800})=>{
    const {camera} = useThree();
    const [loadedTex,setLoadedTex] = useState(null)
    const sphereRef = useRef();
    const sphereColorRef = useRef(0x000000);
    const [texActual,setTexActual] = useState(null);
    const {vistaIsLoading,setVistaIsLoading} = useGlobalState((state)=>({vistaIsLoading:state.vistaIsLoading,setVistaIsLoading:state.setVistaIsLoading}))

    useFrame((state,delta)=>{
        gsap.ticker.tick(delta);
    },[])

    const fadeFromTo = useCallback((from,to,duration=1)=>{
        return new Promise((res,rej)=>{
            if (!sphereRef.current || !sphereRef.current.material) return;
            sphereRef.current.material.opacity = from;
            
            gsap.context(async ()=>{
                gsap.fromTo(sphereRef.current.material,{opacity:(from==='current')? sphereRef.current.material.opacity : from}, {opacity: to,duration:duration,onStart:()=>{sphereColorRef.current = 0xffffff;}}).then(res)
            })
        })
        
    },[])

    useEffect(()=>{
      if (!loadedTex) return
      //init
      loadedTex.mapping = EquirectangularRefractionMapping; 
      loadedTex.refractionRatio = 90;
      loadedTex.wrapS = RepeatWrapping; 
      loadedTex.repeat.x = -1;
      loadedTex.flipY = true;
      setVistaIsLoading(true)
      fadeFromTo(1,0,2).then(()=>{
        setTexActual(loadedTex);
        setVistaIsLoading(false);
        fadeFromTo(0,1,4);
      })
    },[loadedTex])

    useEffect(()=>{
      setVistaIsLoading(true);
      if (!tex) return;
      const loader = new TextureLoader();
      loader.load(tex,(loadedTex)=>{
        setLoadedTex(loadedTex)
      })
    },[tex])

    useEffect(()=>{ 
      // show spinner
      setVistaIsLoading(true)
      sphereRef.current.material.opacity = 0;
      if (!tex) return;
      const loader = new TextureLoader();
      loader.load(tex,(loadedTex)=>{
        setLoadedTex(loadedTex)
      })
    },[])

    return (
      <>
        <ConditionalControls />
        {!!vistaIsLoading && <LoadSpinner3D />}
        <mesh ref={sphereRef} rotation={[0,degToRad(90),0]}>
            <sphereGeometry args={[size,600]} />
            {/* <boxGeometry args={[size,size,size]} /> */}
            <meshBasicMaterial envMap={texActual} side={BackSide} transparent={true} color={sphereColorRef.current} /> 
        </mesh>
      </>
    )
}

export default VistaView