import { Box, Typography } from '@mui/material';
import styles from './styles.module.scss';

const MenuItem=({title,click})=>{
  return (
    <Box className={styles.MenuItem} onClick={click}>
      <Typography variant="MainMenuItem">{title}</Typography>
    </Box>
  )
}

export default MenuItem