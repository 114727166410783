import { Box, Typography } from '@mui/material';
import styles from './styles.module.scss';
import defaultThumb from '../../assets/baseWorldThumbs/BW00-Mechanical-Forests.jpg'
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import AuthGate from '../AuthGate'
import AuthedBaseWorldThumbnail from './authedThumbnail';
import UnAuthedBaseWorldThumbnail from './unAuthedThumbnail';
import useUserState from '../../Stores/userStateManager'
import {maxUnAuthedWorldViews} from '../../Utils/authRules';
const BaseWorldThumbnail=({thumb=defaultThumb,worldName="Unnamed World",worldGuid="BWOO-Mechanical-Forests",sx={}})=>{
  const {recentlyVisitedWorlds} = useUserState((state)=>({recentlyVisitedWorlds:state.recentlyVisitedWorlds}))

  return (
    <>
      <AuthGate
        authedComponent={
          <AuthedBaseWorldThumbnail thumb={thumb} worldName={worldName} worldGuid={worldGuid} />
        }
        unAuthedComponent={<>
          {!!((recentlyVisitedWorlds?.length >= maxUnAuthedWorldViews) && (recentlyVisitedWorlds.indexOf(worldGuid) === -1)) &&
          <UnAuthedBaseWorldThumbnail thumb={thumb} worldName={worldName} worldGuid={worldGuid} />}
         { !((recentlyVisitedWorlds?.length >= maxUnAuthedWorldViews) && (recentlyVisitedWorlds.indexOf(worldGuid) === -1)) &&
          <AuthedBaseWorldThumbnail thumb={thumb} worldName={worldName} worldGuid={worldGuid} />}
        </>}
      />
    </>
  )
}

export default BaseWorldThumbnail