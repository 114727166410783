import styles from './styles.module.scss';
import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';
import galaxyVid from '../../assets/galaxy-2023-11-27-05-01-20-utc.mp4'
const VidBG=({})=>{
  const vidRef = useRef()
  // useEffect(()=>{
  //   console.log('!@ VidBG, mobileNavIsOpen:',mobileNavIsOpen)
  //   if (mobileNavIsOpen) {
  //     vidRef.current.pause()
  //   }
  //   else {
  //     vidRef.current.play();
  //   }
  // },[mobileNavIsOpen])
  return (
    <Box className={styles.VidBG}>
      {/* <Box className={styles.VidBGOverlay} /> */}
      <video ref={vidRef} src={galaxyVid} muted={true} loop autoPlay={true}/>
    </Box>
  )
}

export default VidBG