import { experimental_extendTheme as extendTheme } from '@mui/material/styles'

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1250,
  xl: 1536,
  hd: 1920,
  ultrawide: 3440
}

const theme = extendTheme({
  // palette: {
  //   UltraPlush: {
  //     cyan: '#00aeef'
  //   }
  // },
  shape: {
    headerNav: {
      height: {
        L: '70px'
      },
      zIndex: '9998'
    },
    subHeaderNav: {
      height: {
        L: '65px'
      },
      zIndex: '9999'
    },
    mobileNav: {
      height: '70px',
      zIndex: '9999',
      itemHeight: '70px',
      subItemHeight: '60px',
    },
    basePageTemplate: {
      baseContentZIndex: 2,
      userNavDrawerWidth: {
        ultrawide: '500px',
        hd: '420px',
        xl: '420px',
        lg: '420px',
        md: '400px',
        sm: '350px',
        xs: '100%'
      },
      userNavDrawerZIndex: '20000'
    }
  },
  typography: {
    fontFamily: '"ofelia-display", sans-serif'
  },
  palette: {
    mode: 'dark',
    UltraPlush: {
      cyan: "#00aeef",
      magenta: "#ec008c"
    },
    Outworlds: {
      loginBlue: "#0c8de7",
    }
  },
  breakpoints: {
    values: breakpoints,
  }
  
})

theme.typography.HomePageHeader = {
  fontSize: "60px",
  textAlign:"center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "40px"
  }
}
theme.typography.PromptHeader = {
  fontWeight: '400',
  marginBottom: '10px'
}
theme.typography.WorldMenuDrawerAppName = {
  marginTop: '15px',
  marginBottom: '30px',
  marginLeft: '15px'
}
theme.typography.WorldMenuDrawerVisiting = {
  textAlign: 'center'
}
theme.typography.WorldMenuDrawerWorldName = {
  textAlign: 'center',
  marginTop: '10px'
}
theme.typography.WorldMenuDrawerBy = {
  textAlign: 'center',
  fontSize: '0.9em',

}
theme.typography.WorldMenuDrawerCreator = {
  textAlign: 'center',
  fontSize: '0.95em',
}
theme.typography.DrawerAccordionVistaDescription = {
  fontSize: '1em',
  lineHeight: '1.6em',
  display: 'block',
  fontStyle: 'italic',
  padding: '0 8px',
  marginBottom: '20px'
}
theme.typography.RequestingTerraform = {
  fontFamily: '"input-mono", monospace',
  fontSize: "21px",
  fontWeight: 100,
  padding: '0 20px'
}
theme.typography.TerraformInProgress = {
  fontFamily: '"input-mono", monospace',
  fontSize: "23px",
  fontWeight: 100,
  alignSelf: "flex-start",
  margin: "5px 20px 5px 5px",
  color: 'var(--mui-palette-UltraPlush-cyan)',
}

theme.typography.IncomingScanReport = {
  fontFamily: '"input-mono", monospace',
  fontWeight: 100,
  fontStyle: 'italic',
  color: '#fff',
  width: '300px'
}

theme.typography.TerraformETA = {
  fontFamily: '"input-mono", monospace',
  fontWeight: 100,
  fontSize: '22px',
  marginRight: '0.8em',
  position: 'relative',
  top: '1.25px'
}

theme.typography.TerraformCountdownTimer = {
  fontFamily: '"input-mono", monospace',
  fontWeight: 800,
  fontSize: '35px',
  color: '#04de32'
}

theme.typography.TerraformCountdownTimerOvertime = {
  fontFamily: '"input-mono", monospace',
  fontWeight: 400,
  fontSize: '35px',
  color: '#ec008c'
}

theme.typography.floatingSubheaderDefault = {
  fontSize: "37px",
  fontWeight: 500,
  textAlign:"center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "30px"
  }
}

theme.typography.baseWorldThumbnail = {
  fontSize: "27px",
  margin: "0",
  textAlign: "center",
  width: "100%",
  display: "block",
  lineHeight: "1em",
  marginBottom: "1em",
  marginTop: "0.3em"
}

theme.typography.loginTeaseHeader = {
  textAlign: "center",
  lineHeight: "1.1em",
  marginBottom: "1em"
}

theme.typography.loginTeaseSubheader = {
  textAlign: 'center',
  fontSize: '1.18em',
  fontWeight: 500
}

theme.typography.loginTeaseItem = {
  fontSize: "1.08em"
}

theme.typography.loginFormHeader = {
  fontWeight: 400,
  textAlign: "center",
  marginBottom: "25px"
}

theme.typography.loginSubheader = {
  textAlign: 'center',
  fontSize: '0.95em',
  fontWeight: 400
}

theme.typography.loginNotice = {
  fontSize: "0.8em",
  opacity: 0.5,
  display: "block",
  textAlign: "center",
  marginTop: "12px"
}

theme.typography.newUsername = {
  fontSize: '1.35em',
  marginBottom: '0.5em'
}

theme.typography.newUsernameOptional = {
  fontSize: '1em',
  marginBottom: '0.5em',
  opacity: 0.4
}

theme.typography.InsufficientStardustHeader = {
  textAlign: 'center',
  fontWeight: 500,
  textShadow: '5px 5px 5px rgba(0,0,0,0.5)'
}

theme.typography.InsufficientStardustSubHeader = {
  textAlign: 'center',
  fontSize: '1em',
  marginTop: '0.5em',
  fontWeight: 400,
  maxWidth: '80%',
  margin: '0 auto',
  textShadow: '5px 5px 5px rgba(0,0,0,0.5)'
}

theme.typography.SelectStardustModalHeader = {
  textAlign: "center",
  fontWeight: 400,
  fontSize: "1.7em",
  margin: "0.1em"
}

theme.typography.StardustSelectionOptionTitle ={
  fontSize: '1.1em',
  opacity: 0.6,
  marginBottom: '5px'
}
theme.typography.StardustSelectionOptionQty ={
  fontSize: '1.2em',
}
theme.typography.StardustSelectionOptionProduct = {
  marginTop: '-7px',
  marginBottom: '7px'
}
theme.typography.StardustSelectionOptionPrice = {
  fontSize:'1.1em',
  position: 'relative',
  top: '5px'
}

theme.typography.MainMenuItem = {
  color: '#fff'
}

theme.typography.ConfirmTerraformHeader = {
  textAlign: "center",
  textShadow: '5px 5px 5px rgba(0,0,0,0.5)'
}

theme.typography.ConfirmTerraformSubHeader = {
  textAlign: "center",
  fontWeight: 500,
  fontSize: "1em",
  textShadow: '5px 5px 5px rgba(0,0,0,0.5)'
}

theme.typography.trackTitleDisplay={
  fontSize: "12px",
  opacity: 0.3,
  letterSpacing: "0.5px",
  transition: 'opacity 1500ms, font-size 1500ms',
  whiteSpace: 'nowrap'
  // cursor: 'pointer'
}

export default theme;