import { Box, Typography } from '@mui/material';
import styles from './styles.module.scss';
import useGlobalState from '../../Stores/globalStateManager';
import useAuthState from '../../Stores/authStateManager';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import MenuItem from './menuItem'
import { useCallback, useEffect, useRef } from 'react';
import SelectStardustModal from '../Modals/misc/SelectStardustModal';
import useUserState from '../../Stores/userStateManager';
import stardustPhial from '../../assets/img/stardustPhialIcon1.webp'
const MainMenu=({})=>{
  const {setShowLoginFlow,setShowLoginTease,setMainMenuOpen,setStandardModals} = useGlobalState((state)=>({setShowLoginFlow:state.setShowLoginFlow,setShowLoginTease:state.setShowLoginTease,setMainMenuOpen:state.setMainMenuOpen,setStandardModals:state.setStandardModals}))
  const {currentAuthenticatedUser,doLogout} = useAuthState((state)=>({currentAuthenticatedUser:state.currentAuthenticatedUser,doLogout:state.doLogout}))
  const {stardustQty} = useUserState((state)=>({stardustQty:state.stardustQty}))
  const navTo = useNavigate();
  const {basePage} = useParams();
  const mainMenuRef = useRef();

  const doMenuAction = useCallback((action)=>{
    setMainMenuOpen(false);
    action();
  },[setMainMenuOpen])

  const clickOutsideCloseMenu = useCallback((e)=>{
    if (!mainMenuRef?.current?.contains(e.target)) {
      setMainMenuOpen(false)
      window.removeEventListener('click',clickOutsideCloseMenu)
    }
  },[setMainMenuOpen])


  useEffect(()=>{
    setTimeout(()=>{window.addEventListener('click',clickOutsideCloseMenu)},250)
    return ()=>{
      window.removeEventListener('click',clickOutsideCloseMenu)
    }
  },[])

  return (
    <Box className={styles.MainMenu} ref={mainMenuRef}>
      {!currentAuthenticatedUser && <MenuItem title="Login" click={()=>{doMenuAction(()=>{setShowLoginFlow(true)})}}/>}
      {!!currentAuthenticatedUser &&
        <Box className={styles.welcomeBack}>
          <Typography variant="welcomeBack" component="p">Welcome, {currentAuthenticatedUser.attributes.preferred_username}.</Typography>
          <div>
            <span>{stardustQty}</span>
            <img src={stardustPhial} />
          </div>
          
          {/* <Typography variant="welcomeBack" component="p">You have {stardustQty} Stardust</Typography> */}
        </Box>
      }
      {basePage === "create" &&
          <>
            <MenuItem title="Explore" click={()=>{doMenuAction(()=>{navTo('/explore')})}} />
            <MenuItem title="Community" click={()=>{doMenuAction(()=>{alert('Community features coming soon!')})}} />
          </>
        }
        
      {basePage === "explore" &&
        <>
          <MenuItem title="Create" click={()=>{doMenuAction(()=>{navTo('/create')})}} />
          <MenuItem title="Community" click={()=>{doMenuAction(()=>{alert('Community features coming soon!')})}} />
        </>
        } 
      {/* {!currentAuthenticatedUser && <MenuItem title="Buy Stardust" click={()=>{doMenuAction(()=>{setShowLoginTease(true)})}} />} */}
      {!!currentAuthenticatedUser && <MenuItem title="Buy Stardust" click={()=>{doMenuAction(()=>{setStandardModals(<SelectStardustModal />)})}} />}
      {!!currentAuthenticatedUser && <MenuItem title="Logout" click={()=>{doMenuAction(()=>{doLogout()})}}/>}
    </Box>
  )
}

export default MainMenu