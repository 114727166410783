import { Box, Button, Typography } from '@mui/material';
import styles from './styles.module.scss';
import outOfStardust from '../../../../assets/img/outOfStardust.webp';
import useGlobalState from '../../../../Stores/globalStateManager';
import { useCallback } from 'react';
import SelectStardustModal from '../SelectStardustModal';
const InsufficientStardust=({})=>{
  const {setStandardModals} = useGlobalState((state)=>({setStandardModals:state.setStandardModals}))

  const showSelectStardustModal = useCallback(()=>{
    setStandardModals(<SelectStardustModal />)
  },[setStandardModals])

  return (
    <Box className={styles.InsufficientStardust}>
      <Box className={styles.inner}>
        <div>
          <Typography variant="InsufficientStardustHeader" component="h2">Oh no!</Typography>
          <Typography variant="InsufficientStardustHeader" component="h2">You're out of Stardust!</Typography>
          <Typography variant="InsufficientStardustSubHeader" component="h3">It takes at least 10 Stardust to terraform.</Typography>
        </div>
        <Button className={styles.GetButton} onClick={showSelectStardustModal}>Get more Stardust!</Button>
      </Box>
      <img src={outOfStardust} />
    </Box>
  )
}

export default InsufficientStardust