import { Box, Drawer, Typography } from '@mui/material';
import styles from './styles.module.scss';
import useGlobalState from '../../Stores/globalStateManager';
import VistaAccordions from './vistaAccordions';
import ResetButton from '../ResetButton';
import { useNavigate } from 'react-router-dom';

const WorldMenuDrawer=({})=>{
  const {worldMenuOpen,setWorldMenuOpen,localWorld} = useGlobalState((state)=>({worldMenuOpen:state.worldMenuOpen,setWorldMenuOpen:state.setWorldMenuOpen,localWorld:state.localWorld}))
  const navTo = useNavigate();
  console.log('WorldMenuDrawer localWorld ',localWorld)
  return (
    <Drawer
      anchor="right"
      open={worldMenuOpen}
      onClose={()=>{setWorldMenuOpen(false)}}
      PaperProps={{
        sx: {
          boxShadow: '-10px 0px 39px 0px rgba(0,0,0,0.7);'
        }
      }}
    >
      <Box className={styles.worldMenuDrawerInner}>
        {/* app name all fancy */}
        <Typography variant="WorldMenuDrawerAppName" component="h1">{"Outworlds"}</Typography>
        {/* world name all fancy */}
        <Typography variant="WorldMenuDrawerVisiting" component="p">visiting world:</Typography>
        <Typography variant="WorldMenuDrawerWorldName" component="h3">{!!localWorld?.name && localWorld.name}</Typography>
        <Typography variant="WorldMenuDrawerBy" component="p">by:</Typography>
        <Typography variant="WorldMenuDrawerCreator" component="h4">{(!localWorld?.createdBy || localWorld.createdBy === "TODO")? 'A Fellow Traveler':localWorld.createdBy}</Typography>
        {/* accordions */}
        <VistaAccordions vistas={localWorld?.vistas?.items} />
        {/* reset */}
        <ResetButton handler={()=>{navTo(`${process.env.PUBLIC_URL}/`)}} />
      </Box>
    </Drawer>
  )
}

export default WorldMenuDrawer