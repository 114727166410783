import { API } from 'aws-amplify';
import { onUpdateWorld } from  '../../graphql/subscriptions';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
const SubscriptionDebug=({})=>{
  let sub
 
  const doSubscribe = async()=>{
    sub = API.graphql({
        query:onUpdateWorld,
        // authMode: GRAPHQL_AUTH_MODE.API_KEY,  // FAILS
        // authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS, // FAILS
        variables: {
          filter: {
            id: {
              eq: "ee0fd244-fd5a-49b8-bbee-8f804d74be3a"
            }
          }
        }
      }).subscribe({
        next: ({provider,value})=>{console.log('!!provider',provider,'value',value)},
        error: (error)=>console.log('error: ',error)
      })
      console.log('sub is',sub)
  }
 
  return (
    <div>
      <button onClick={doSubscribe}>test subscription</button>
    </div>
  )
}

export default SubscriptionDebug