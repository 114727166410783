import { Box, Typography } from '@mui/material';
import styles from '../styles.module.scss';
import {ReactComponent as TerraformSatellite} from '../../../../assets/noun-satellite-6597828.svg';
import {ReactComponent as RequestSatellite} from '../../../../assets/noun-satellite-1615950.svg';
const RequestingTerraform=({})=>{
  return (
    <Box className={styles.RequestingTerraform}>
      <Typography variant="RequestingTerraform">Requesting Terraform...</Typography>
      <Box>
        <RequestSatellite />
        <TerraformSatellite />
      </Box>
      
    </Box>
  )
}

export default RequestingTerraform