import { Box, Typography } from '@mui/material';
import styles from './styles.module.scss';

const OrientationLoader=({})=>{
  return (
    <Box className={styles.OrientationLoader}>
      <Typography variant="OrientationLoader">Receiving Transmission...</Typography>
    </Box>
  )
}

export default OrientationLoader