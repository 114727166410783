import OddNumbers from '../../assets/tunes/Curtis Cole - Odd Numbers2.mp3';
import EverlastingFlower from '../../assets/tunes/DaniHaDani - Everlasting Flower.mp3';
import Redwood from '../../assets/tunes/Laurel Violet - Redwood.mp3';
import NothingCanStopUs from '../../assets/tunes/Nobou - Nothing Can Stop Us.mp3';
import SilentDreams from '../../assets/tunes/Nobou - Silent Dreams.mp3';
const playlist = [
  {
    author: "Curtis Cole",
    title: "Odd Numbers",
    url: OddNumbers
  },
  {
    author: "DaniHaDani",
    title: "Everlasting Flower",
    url: EverlastingFlower
  },
  {
    author: "Laurel Violet",
    title: "Redwood",
    url: Redwood
  },
  {
    author: "Nobou",
    title: "Nothing Can Stop Us",
    url: NothingCanStopUs
  },
  {
    author: "Nobou",
    title: "Silent Dreams",
    url: SilentDreams
  }
]

export default playlist