import { CameraControls } from '@react-three/drei';
import styles from './styles.module.scss';
import CameraOverlay from '../CameraOverlay';

const DragCamera=({})=>{
  return (
    <>
      <CameraOverlay />
      <CameraControls />
    </>
  )
}

export default DragCamera