import { Typography } from '@mui/material';
import useGlobalState from '../../../../Stores/globalStateManager';
import styles from '../styles.module.scss';
import Carousel from 'react-material-ui-carousel'
import { useState } from 'react';

const ScanReportCarousel=({})=>{
  const {scanReports} = useGlobalState((state)=>({scanReports:state.scanReports}))
  const [shouldAutoPlay,setShouldAutoPlay] = useState(true);
  return (
    <>
      <Carousel
        autoPlay={shouldAutoPlay}
        interval={(60/3)*1000}
      >
        {scanReports.map((scanReport)=>{ 
          return <Typography key={scanReport.trim(20)} variant="IncomingScanReport" onClick={()=>{setShouldAutoPlay(false)}}>{scanReport}</Typography>
        })}
      </Carousel>
    </>
  )
}

export default ScanReportCarousel