
import styles from './styles.module.scss';
import { Box, Typography } from '@mui/material';

const SelectionOption=({name="Scout",qty=100,price="5.00",selected=false,idx=0,selectedOptionIdx=0,setSelectedOptionIdx=()=>{}})=>{
  return (
    <Box className={(idx === selectedOptionIdx)? styles.SelectionOptionSelected:styles.SelectionOption} onClick={()=>{setSelectedOptionIdx(idx)}}>
      <Typography variant="StardustSelectionOptionTitle" component="p">{name}</Typography>
      <Typography variant="StardustSelectionOptionQty" component="p">{qty}</Typography>
      <Typography variant="StardustSelectionOptionProduct" component="p">Stardust</Typography>
      <Typography variant="StardustSelectionOptionPrice" component="p">${price}</Typography>
    </Box>
  )
}

export default SelectionOption