import { Box } from '@mui/material';
import styles from './styles.module.scss';
import VidBG from '../../../Components/VidBG';

const BasePageTemplate=({children})=>{
  return (
    <Box className={styles.BasePageTemplate}>
      <VidBG />
      {children}
    </Box>
  )
}

export default BasePageTemplate