import { Html } from '@react-three/drei';
import styles from './styles.module.scss';
import { useCallback, useRef } from 'react';
import { saveAs } from 'file-saver';
import { useThree } from '@react-three/fiber';
import gsap from 'gsap';
const StaticCameraOverlay=({})=>{
  const shutterRef = useRef();
  const doSnapShot = useCallback(()=>{
    // animate shutter
    gsap.context(async ()=>{
        gsap.fromTo(
          shutterRef.current,
          {
            opacity:1
          }, 
          {
            opacity: 0,
            duration:1,
          }
        )
    })
    setTimeout(()=>{
      const _canvas = document.querySelector('#mainRenderCanvas canvas');
          const canvasData = _canvas.toDataURL('image/jpeg', 1.0);
          saveAs(canvasData, `Outworlds-${Date.now()}.jpg`);
    },600)
  },[])

  return (
    <div className={styles.CameraOverlayStatic}>
      <div className={styles.shutter} ref={shutterRef}/>
      <div className={styles.reticle}>
        <div className={styles.topLeft}/>
        <div className={styles.topRight}/>
        <div className={styles.bottomLeft}/>
        <div className={styles.bottomRight}/>
        <div className={styles.center}/>
        <div className={styles.centerCross}/>
        <div className={styles.shutterButtonStatic} onClick={doSnapShot}/>
      </div>
    </div>
  )
}

export default StaticCameraOverlay