import { VRButton } from '@react-three/xr';
import styles from './styles.module.scss';
import { useCallback, useMemo } from 'react';

const ConditionalVRButton=({})=>{

  const isWebXRSupported = useMemo(() => {
    return !(navigator.xr === undefined || window.location.protocol.indexOf('https') === -1);
  },[])

  return (
    <>
      {!!isWebXRSupported && <VRButton />}
    </>
  )
}

export default ConditionalVRButton