import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import styles from './styles.module.scss';
import { MuiTelInput } from 'mui-tel-input';
import useAuthState from '../../../Stores/authStateManager';
import { useCallback, useState } from 'react';
import UsernameForm from './usernameForm';
import LoginForm from './loginForm';
import VerifyCodeForm from './verifyCodeForm';
import useGlobalState from '../../../Stores/globalStateManager';
const LoginFlowModal=({})=>{
  const {doSignUp,doLogin,checkUserExists} = useAuthState((state)=>({doSignUp:state.doSignUp,doLogin:state.doLogin,checkUserExists:state.checkUserExists}))
  const {setShowLoginFlow} = useGlobalState((state)=>({setShowLoginFlow:state.setShowLoginFlow}))
  const [phoneNo,setPhoneNo] = useState('');
  const [showUsernameModal,setShowUsernameModal] = useState(false);
  const [showVerifyModal,setShowVerifyModal] = useState(false);
  const [username,setUsername] = useState('');

  const onPhoneNoChange = useCallback((newVal)=>{
    console.log('setting phone no to ', newVal) 
    setPhoneNo(newVal);
  },[])

  const fireLogin = useCallback((isSignup=false)=>{
    doLogin(phoneNo,isSignup)
  },[phoneNo])

  const fireSignup = useCallback((username)=>{
    console.log('fireSignup phoneNo',phoneNo,'username',username)
    doSignUp(phoneNo,username)
  },[phoneNo,username])

  return (
    <>
      <Modal
        open={true}
        onClose={()=>{setShowLoginFlow(false)}}
      >
        <>
          {!showVerifyModal && !showUsernameModal && <LoginForm setShowUsernameModal={setShowUsernameModal} setShowVerifyModal={setShowVerifyModal} onPhoneNoChange={onPhoneNoChange} phoneNo={phoneNo} checkUserExists={checkUserExists} fireLogin={fireLogin} />}
          {!showVerifyModal && !!showUsernameModal && <UsernameForm username={username} setUsername={setUsername} setShowVerifyModal={setShowVerifyModal} fireLogin={fireLogin} fireSignup={fireSignup} phoneNo={phoneNo}/> }
          {!!showVerifyModal && <VerifyCodeForm phoneNo={phoneNo}/>}
        </>
      </Modal>
    </>
  )
}

export default LoginFlowModal