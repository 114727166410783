import { Box, Typography } from '@mui/material';
import styles from './styles.module.scss';
import useGlobalState from '../../Stores/globalStateManager';
// import {getWorld} from '../../graphql/queries';

import { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import TerraformButton from './terraformButton';
import useAuthState from '../../Stores/authStateManager';

import RequestingTerraform from '../Modals/TerraformModal/subComponents/requestingTerraform';
import TerraformInProgress from '../Modals/TerraformModal/subComponents/terraformInProgress'
import InsufficientStardust from '../Modals/misc/InsufficientStardust';
import { terraformOutworld } from '../../graphql/mutations';
import { onUpdateWorldStatus, onUpdateWorld } from  '../../graphql/subscriptions';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { API } from 'aws-amplify';
import { getWorldDetails } from '../../customQueries/customQueries'
import useUserState from '../../Stores/userStateManager';
import ConfirmTerraform from '../Modals/misc/ConfirmTerraform';


// const getWorld= `
//   query getWorld($id: ID!) {
//     getWorld(
//       id: $id
//     ) {
//       id
//       status
//       name
//       updatedAt
//       createdAt
//       status
//       vistas {
//         items {
//           id
//           name
//           url
//         }
//       }
//     }
//   }
// `

const WorldPromptInput=({})=>{
  const {worldPrompt,setWorldPrompt,worldPromptMaxLengthChars,worldName,setWorldName,worldNameMaxLengthChars,setLocalWorld,setCurrentVistaIdx,setTerraformModals,setScanReports,setStandardModals} = useGlobalState((state)=>({worldPrompt:state.worldPrompt,setWorldPrompt:state.setWorldPrompt,worldPromptMaxLengthChars:state.worldPromptMaxLengthChars,worldName:state.worldName,setWorldName:state.setWorldName,worldNameMaxLengthChars:state.worldNameMaxLengthChars,setLocalWorld:state.setLocalWorld,setCurrentVistaIdx:state.setCurrentVistaIdx,setTerraformModals:state.setTerraformModals,setScanReports:state.setScanReports,setStandardModals:state.setStandardModals}))
  const {currentAuthenticatedUser} = useAuthState((state)=>({currentAuthenticatedUser:state.currentAuthenticatedUser}))
  const {updateStardustFromServer,stardustQty} = useUserState((state)=>({updateStardustFromServer:state.updateStardustFromServer,stardustQty:state.stardustQty}));
  const subRef = useRef();
  const navTo = useNavigate();

  const doTerraform = useCallback(async ()=>{
    if (!currentAuthenticatedUser) return;
    let newWorld;
    setCurrentVistaIdx(0);
    setTerraformModals(<RequestingTerraform />) 
    try {
      newWorld =  await API.graphql({
        query:terraformOutworld, 
        variables:{
          worldName:worldName,
          worldPrompt:worldPrompt
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        // headers: {
        //   Authorization: `Bearer ${currentAuthenticatedUser.signInUserSession.idToken.jwtToken}`
        // },
        // authMode: GRAPHQL_AUTH_MODE.AWS_IAM

      })
    }
    catch(err){
      // TODO error handling
      setTerraformModals([]);
      console.error("Terraforming failed:",err)
      if (err?.errors[0]?.message === "Insufficient Stardust") {
        // TODO purchase nag
        // alert ('insufficient stardust!')
        setStandardModals([<InsufficientStardust />])
      }
      return null;
    }
    newWorld = JSON.parse(newWorld.data.terraformOutworld);
    console.log('newWorld is',newWorld)
    console.log('subscribing to',newWorld.worldId)
    // TODO setScanReports
    setScanReports(newWorld.prompts);
    // TODO set terraform modal to terraform in progress
    setTerraformModals([<TerraformInProgress />]) 
    // worldId is newWorld.worldId
    // subscribe to updates
    // let sub;
    subRef.current = API.graphql({
      query: onUpdateWorld,
      variables: { 
        filter: {
          id: {
            eq:newWorld.worldId
          }
        }
      },
      //authMode: GRAPHQL_AUTH_MODE.API_KEY
    }).subscribe({
      next: async ({ provider, value }) => {
        console.log('new World Update: provider,value', provider, value )
        const worldDetails = await getWorldDetails(newWorld.worldId);
        console.log('!2 worldDetails',worldDetails)
        if (!!worldDetails?.id) {
          setTerraformModals([]);
          setLocalWorld(worldDetails);
          navTo(`${process.env.PUBLIC_URL}/worldView/${worldDetails.id}`)
        }
      },
      error: (error) => console.warn(error)
    });
    console.log('subscription to:',newWorld.worldId,'created. subRef.current is',subRef.current)
    updateStardustFromServer();
  },[worldPrompt,worldName,setScanReports,setTerraformModals])

  window.doTerraform = doTerraform;

  const confirmTerraform = useCallback(async()=>{
    setStandardModals(<ConfirmTerraform doTerraform={doTerraform} />)
  },[doTerraform,setStandardModals])

  return (
    <Box className={styles.WorldPromptInput}>
      <Typography variant="PromptHeader" component="h3">Describe your world:</Typography>
      <textarea
        value={worldPrompt}
        onChange={e => setWorldPrompt(e.target.value)}
      />
      <Box className={styles.secondRow}>
        <Typography variant="promptCount">{`${worldPrompt.length}/${worldPromptMaxLengthChars}`}</Typography>
      </Box>
      <Box className={styles.thirdRow}>
        <Box className={styles.nameRow}>
          <Typography variant="PromptHeader" component="h3">Name your world (optional):</Typography>
          <Box>
            <textarea
              value={worldName}
              onChange={e => setWorldName(e.target.value)}
            />
            <Typography variant="promptCount">{`${worldName.length}/${worldNameMaxLengthChars}`}</Typography>
          </Box>
        </Box>
        
        <TerraformButton confirmTerraform={confirmTerraform} />
      </Box>
    </Box>
  )
}

export default WorldPromptInput