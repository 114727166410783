import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { API } from 'aws-amplify';

export const getWorld= `
query getWorld($id: ID!) {
  getWorld(
    id: $id
  ) {
    id
    status
    name
    updatedAt
    createdAt
    status
    createdBy
    vistas {
      items {
        id
        name
        url
        prompt
      }
    }
  }
}
`

export const getWorldDetails = async (_worldId,worldDetails)=>{
  // console.log('getting world details')
  try {
    const worldDetails =  await API.graphql({
      query:getWorld, 
      variables:{
        id: _worldId
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    })
    // console.log('!! worldDetails',worldDetails)
    return worldDetails?.data?.getWorld
  }
  catch(err){
    console.error("Terraforming failed:",err)
    return null;
  }
}

export const getBaseWorlds = `
query getBaseWorlds($worldType: String!) {
  worldsByWorldType(
    worldType: $worldType
  ) {
    items {
      id
      status
      name
      updatedAt
      createdAt
      createdBy
      description
      vistas {
        items {
          id
          name
          url
          prompt
        }
      }
    }
  }
}`