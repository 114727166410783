import { Box, Typography } from '@mui/material';
import styles from './styles.module.scss';
import MenuButton from '../MenuButton';
import useGlobalState from '../../Stores/globalStateManager';
import MainMenu from '../MainMenu';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';

const FloatingHeader=({})=>{
  const {mainMenuOpen} = useGlobalState((state)=>({mainMenuOpen:state.mainMenuOpen}))
  const [isSticky,setIsSticky] = useState(false);
  const theme=useTheme(); 
  useEffect(()=>{
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const breakpoint = window.width > theme.breakpoints.values.sm? 140: 20;
      setIsSticky(scrollTop > breakpoint); 
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  },[])
  return (
    <>
      <Box className={styles.FloatingHeader}>
        <Box className={(isSticky)? styles.innerSticky:styles.inner}>
          <Typography variant="HomePageHeader" component="h1">Outworlds</Typography>
          <MenuButton />
          {!!mainMenuOpen && <MainMenu />}
        </Box>
      </Box>
    </>
  )
}

export default FloatingHeader