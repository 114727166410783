import ControlTypePicker from '../../../Components/ControlTypePicker';
import View3D from '../../../Components/View3D';
import WorldMenu from '../../../Components/WorldMenu';
import styles from './styles.module.scss';

const WorldViewTemplate=({children})=>{
  return (
    <>
      <WorldMenu />
      <ControlTypePicker />
      <View3D>
        {children}
      </View3D>
    </>
  )
}

export default WorldViewTemplate