import { Box } from '@mui/material';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

const FloatingBackButton=({navPath="/"})=>{
  const navTo = useNavigate();

  const handleClick = useCallback(()=>{
    navTo(`${process.env.PUBLIC_URL}${navPath}`)
  },[navPath])

  return (
    <button className={styles.FloatingBackButton} onClick={handleClick}>
      &laquo; Back
    </button>
  )
}

export default FloatingBackButton