import { Box } from '@mui/material';
import styles from './styles.module.scss';
import { Route, Routes, useParams } from 'react-router-dom';
import HomePage from '../HomePage';
import CreatePage from '../CreatePage';
import BasePageTemplate from '../Templates/BasePageTemplate';
import ExplorePage from '../ExplorePage';

const BasePages=({})=>{
  const {basePage} = useParams();
  console.log('basePages basePage=',basePage);
  return (
    <BasePageTemplate>
      <Box className={styles.BasePages}>
        {!(basePage) && 
          <HomePage />
        }
        {!!(basePage === "create") && 
          <CreatePage />
        }
        {!!(basePage === "explore") && 
          <ExplorePage />
        }
      </Box>
    </BasePageTemplate>
  )
}

export default BasePages