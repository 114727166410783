import { Box, Typography } from '@mui/material';
import styles from '../styles.module.scss';
import TerraformCountdownTimer from './terraformCountdownTimer';
import ScanReportCarousel from './scanReportCarousel';

const TerraformInProgress=({})=>{
  return (
    <Box className={styles.TerraformInProgress}>
      <Typography variant="TerraformInProgress">Terraform in progress...</Typography>
      <Box className={styles.TerraformCountdownRow}>
        <Typography variant="TerraformETA">ETA:</Typography>
        <TerraformCountdownTimer />
      </Box>
      <Typography variant="TerraformInProgress">// Incoming Scout Reports ::</Typography>
      <Box className={styles.TerraformScanReports}>
        <ScanReportCarousel />
      </Box>
    </Box>
  )
}

export default TerraformInProgress