import { Box, Paper, Typography } from '@mui/material';
import BasePageTemplate from '../Templates/BasePageTemplate';
import styles from './styles.module.scss';
import WorldPromptInput from '../../Components/WorldPrompt/worldPromptInput';
import WorldPrompt from '../../Components/WorldPrompt';
import { useNavigate } from 'react-router-dom';
import FloatingHeader from '../../Components/FloatingHeader';

const HomePage=({})=>{
  const navTo = useNavigate();
  return (
      <Box className={styles.HomePage}>
        <FloatingHeader />
        <Box className={styles.buttonGang}>
          <button className={styles.shinyButton} onClick={()=>{navTo(`${process.env.PUBLIC_URL}/explore`)}}>Explore</button>
          <button className={styles.shinyButton} onClick={()=>{navTo(`${process.env.PUBLIC_URL}/create`)}}>Create</button>
          <button className={styles.shinyButton} onClick={()=>{alert(`Community Features coming soon!`)}}>Community</button>
        </Box>
      </Box>
  )
}

export default HomePage