import { useCallback, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import VistaAccordion from './vistaAccordion';

const sortByCreatedAt = (a,b)=>{
  return new Date(a.createdAt) - new Date(b.createdAt)
}

const VistaAccordions=({vistas})=>{
  const [currentlyOpenAccordion,setCurrentlyOpenAccordion] = useState(0)
  const Accordions = useCallback(()=>{
    if (!vistas?.length) return null
    return vistas.map((vista,idx)=>{
      return <VistaAccordion key={vista.name} vista={vista} idx={idx} currentlyOpenAccordion={currentlyOpenAccordion} setCurrentlyOpenAccordion={setCurrentlyOpenAccordion} />
    })
  },[vistas,currentlyOpenAccordion])
  // console.log('!VA vistas',vistas)
  return (
    <>

      {!!vistas?.length && <Accordions />}
    </>
  )
}

export default VistaAccordions