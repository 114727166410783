import { Box, Button, Typography } from '@mui/material';
import styles from './styles.module.scss';
import useGlobalState from '../../../../Stores/globalStateManager';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthState from '../../../../Stores/authStateManager';

import outOfStardust from '../../../../assets/img/outOfStardust.webp';
import useUserState from '../../../../Stores/userStateManager';
const ConfirmTerraform=({doTerraform})=>{ 
  const {worldPrompt,worldName,setLocalWorld,setCurrentVistaIdx,setTerraformModals,setScanReports,setStandardModals} = useGlobalState((state)=>({worldPrompt:state.worldPrompt,worldName:state.worldName,setLocalWorld:state.setLocalWorld,setCurrentVistaIdx:state.setCurrentVistaIdx,setTerraformModals:state.setTerraformModals,setScanReports:state.setScanReports,setStandardModals:state.setStandardModals}))
  const {currentAuthenticatedUser} = useAuthState((state)=>({currentAuthenticatedUser:state.currentAuthenticatedUser}));
  const {updateStardustFromServer,stardustQty} = useUserState((state)=>({updateStardustFromServer:state.updateStardustFromServer,stardustQty:state.stardustQty}));
  const navTo = useNavigate();
  

  
  return (
    <Box className={styles.ConfirmTerraform}>
      <Box className={styles.inner}>
        
        <Box className={styles.body}> 
          <Typography variant="ConfirmTerraformHeader" component="h2">Use 10 Stardust to terraform this world?</Typography>
          <Typography variant="ConfirmTerraformSubHeader" component="h3">( You have {stardustQty} Stardust )</Typography>
        </Box>
        <Box className={styles.buttonGang}>
          <Button variant="contained" onClick={()=>{setStandardModals([])}}>No</Button>
          <Button variant="contained" onClick={()=>{
            setStandardModals([]);
            doTerraform();
          }}>Yes</Button>
        </Box>
      </Box>
      <img src={outOfStardust} />
    </Box>
  )
}

export default ConfirmTerraform