import { Billboard, Plane, Text } from '@react-three/drei';
import styles from './styles.module.scss';

const LoadSpinner3D=({})=>{
  return (
    <>
      <Billboard
        follow
      >
        <Plane args={[2,1]} material-color="black" />
        <Text scale={0.2}>Receiving transmission . . . </Text>
      </Billboard>
    </>
  )
}

export default LoadSpinner3D