import { VRButton, XR } from '@react-three/xr';
import styles from './styles.module.scss';
import { Canvas } from "@react-three/fiber"
import { PerspectiveCamera } from '@react-three/drei';
import { Color } from 'three';
import LoadSpinner3D from '../LoadSpinner3D';
import useGlobalState from '../../Stores/globalStateManager';
import OrientationLoader from '../OrientationLoader';
import ConditionalVRButton from '../ConditionalVRButton';
import StaticCameraOverlay from '../CameraOverlay/staticCameraOverlay';
const View3D=({children})=>{
  const {controlTypeSelection,vistaIsLoading,isMotionAvailable,orientationPermsDenied} = useGlobalState((state)=>({controlTypeSelection:state.controlTypeSelection,vistaIsLoading:state.vistaIsLoading,isMotionAvailable:state.isMotionAvailable,orientationPermsDenied:state.orientationPermsDenied}))
  return (
    <>
      {!!(controlTypeSelection === 'orientation' && vistaIsLoading === true) && 
        <>
          <OrientationLoader />
        </>
      }
      {!!(controlTypeSelection === 'camera') && !!(isMotionAvailable) && !(orientationPermsDenied) && <StaticCameraOverlay />}
      <ConditionalVRButton />
      <Canvas  gl={{ preserveDrawingBuffer: true }} environment={new Color(0x000000)} id="mainRenderCanvas" >
        <PerspectiveCamera fov={10} position={[0, 0, 0]}>
        <XR>
          {children}
        </XR>
        </PerspectiveCamera>
      </Canvas>
    </>
  )
}

export default View3D