import { Box, Button, Modal, Typography } from '@mui/material';
import styles from './styles.module.scss';
import useGlobalState from '../../../Stores/globalStateManager';
import { useCallback } from 'react';

const LoginTeaseModal=({})=>{
  const {setShowLoginTease,setShowLoginFlow} = useGlobalState((state)=>({setShowLoginTease:state.setShowLoginTease,setShowLoginFlow:state.setShowLoginFlow}))
  
  const _showLogin = useCallback(()=>{
    if (!setShowLoginFlow) return
    setShowLoginFlow(true);
    setShowLoginTease(false);
  },[setShowLoginFlow,setShowLoginTease])

  return (
    <Modal
      open={true}
      onClose={()=>{setShowLoginTease(false)}}
    >
      <Box className={`standard-modal ${styles.LoginTeaseModal}`}>
        <Box className={`inner ${styles.inner}`}>
          <Typography variant="loginTeaseHeader" component="h2">You must be logged in<br/>to do that!</Typography>
          <Typography variant="loginTeaseSubheader" component="p">Benefits of logging in:</Typography>
          <ul>
            <li>
              <Typography variant="loginTeaseItem">Explore all of the Base Worlds</Typography>
            </li>
            <li>
              <Typography variant="loginTeaseItem">Get 10 Stardust to terraform and explore your own world</Typography>
            </li>
            <li>
              <Typography variant="loginTeaseItem">It's free!</Typography>
            </li>
          </ul>
          <Button variant="contained" onClick={_showLogin}>Login Now</Button> 
        </Box>
      </Box>
    </Modal>
  )
}

export default LoginTeaseModal