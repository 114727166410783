import {create} from 'zustand';
import { subscribeWithSelector, persist, createJSONStorage } from 'zustand/middleware'
import useGlobalState from './globalStateManager';
import useAuthState from './authStateManager';
import {API} from 'aws-amplify'

const userStardustStoreByUserIdQuery = `
query userCurrencyStoreByUserId($user_id:ID!) {
  getUser(id: $user_id) {
    currencyStore {
      qty
    }
  }
}
`

const useUserState = create(
  persist(subscribeWithSelector((set,get)=>({
    recentlyVisitedWorlds: [],
    addToRecentlyVisitedWorlds: (newWorldId)=>{
      const _worlds = get().recentlyVisitedWorlds;
      _worlds.push(newWorldId);
      set({recentlyVisitedWorlds:_worlds})
    },
    recentlyVisitedVistas: [],
    addToRecentlyVisitedVistas: (newVistaId)=>{
      const _vistas = get().recentlyVisitedVistas;
      _vistas.push(newVistaId);
      set({recentlyVisitedVistas:_vistas})
    },
    stardustQty: 0,
    setStardustQty: (newQty)=>{
      set({stardustQty:newQty})
    },
    updateStardustFromServer: async ()=>{
      const _authState = useAuthState.getState()
      if (!_authState.currentAuthenticatedUser) {
        console.log('error updating stardust: no logged in user')
      }
      try {
        console.log('fetching stardust for user_id',_authState?.currentAuthenticatedUser?.attributes?.sub )
        const _userStardust = API.graphql({
          query: userStardustStoreByUserIdQuery, 
          variables: {
            user_id: _authState?.currentAuthenticatedUser?.attributes?.sub 
          },
          // authMode: GRAPHQL_AUTH_MODE.API_KEY,
        })
        .then((resp)=>{
          console.log('_userStardust resp',resp);
          // TODO UPDATE LOCAL STORE
          set({stardustQty:resp?.data?.getUser?.currencyStore?.qty})
        })
        .catch((err)=>{
          console.log('error fetching user stardust',err);
        })
      }
      catch(err){
        // TODO error handling
        console.error("failed checking user stardust:",err)
        return null;
      }
    }
  })),
  {
    name: 'Outworlds-User',
    storage: createJSONStorage(()=>localStorage)
  }
  )
)

export default useUserState