import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import playlist from './playlist'
import { Box } from '@mui/system';
import styles from './styles.module.scss'
import { useLocation } from 'react-router';
import { Typography } from '@mui/material';
import {ReactComponent as PlayIcon} from '../../assets/icons/noun-play-1423157-FFFFFF.svg'
import {ReactComponent as PauseIcon} from '../../assets/icons/noun-pause-104883-FFFFFF.svg'
import {ReactComponent as NextIcon} from '../../assets/icons/noun-next-arrow-1423155-FFFFFF.svg'
import { useTheme } from '@emotion/react';
const MusicPlayer = ({}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTrack,setCurrentTrack] = useState(0)
  const [isSticky,setIsSticky] = useState(false);
  const audioRef = useRef(null);
  const theme=useTheme();
  const location = useLocation()
  console.log('MM: location:',location)

  const viewType = useMemo(()=>{
    if (location.pathname.indexOf('worldView') !== -1) {
      return 'worldView'
    }
    else {
      return 'menuView'
    }
  },[location])

  const doPause = useCallback(()=>{
    audioRef.current.pause();
    setIsPlaying(false)
  },[])

  const doPlay = useCallback(()=>{
    audioRef.current.play();
    setIsPlaying(true)
  },[])

  const doNext = useCallback(()=>{
    if (currentTrack < playlist.length - 1) { // more tracks left
      setCurrentTrack(currentTrack+1);
      setTimeout(()=>{doPlay()},500)
    }
    else { // last track
      setCurrentTrack(0)
    }
  },[currentTrack,playlist,doPlay])

  const doPrevious = useCallback(()=>{
    if (currentTrack > 0) {
      setCurrentTrack(currentTrack-1);
    }
    else {
      setCurrentTrack(playlist.length-1)
    }
  },[currentTrack,playlist])

  useEffect(() => {
    if (!audioRef?.current || !doNext) return;
    const handleEnded = () => {
      doPause();
      doNext();
    };

    // Attach the event listener on component mount
    audioRef.current.addEventListener('ended', handleEnded);

    // Clean up the event listener on component unmount
    return () => audioRef.current.removeEventListener('ended', handleEnded);
  }, [audioRef,doNext]);  

  useEffect(()=>{
    const doInit = (e)=>{
      audioRef.current.volume = 0.12;
      try {
        console.log('MM doInit e',e);
        audioRef.current.play();
        setIsPlaying(true);
      }
      catch(err){
        // shh
      }
      window.removeEventListener('click',doInit)
      window.removeEventListener('keydown',doInit)
      // window.removeEventListener('scroll',doInit)
    }
    window.addEventListener('click',doInit)
    window.addEventListener('keydown',doInit)
    // window.addEventListener('scroll',doInit)
  },[])

  useEffect(()=>{
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const breakpoint = window.width > theme.breakpoints.values.sm? 140: 20;
      setIsSticky(scrollTop > breakpoint); 
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  },[])


  return (
    <Box className={(viewType === 'worldView')? (isSticky)? styles.MusicPlayerWorldViewSticky:styles.MusicPlayerWorldView : (isSticky)? styles.MusicPlayerMenuViewSticky:styles.MusicPlayerMenuView}>
      <audio ref={audioRef} src={playlist[currentTrack].url} preload="auto" />
      <Box className={(isSticky)? styles.trackNameBoxSticky:styles.trackNameBox}>
        <Typography variant="trackTitleDisplay">{`${playlist[currentTrack].title} - ${playlist[currentTrack].author}`}</Typography>
      </Box>
      <div className={styles.buttonGang}>
        <button onClick={doPrevious} className={styles.prevBtn}><NextIcon /></button>
        {!!(isPlaying) && <button onClick={doPause} className={styles.pauseBtn}><PauseIcon /></button>}
        {!(isPlaying) && <button onClick={doPlay} className={styles.playBtn}><PlayIcon /></button>}
        <button onClick={doNext} className={styles.nextBtn}><NextIcon /></button>
      </div>
    </Box>
  );
};

export default MusicPlayer;
