import { useCallback, useState } from 'react';
import styles from './styles.module.scss';
import { Box, Button, TextField, Typography } from '@mui/material';
import { validate } from 'uuid';

const UsernameForm=({username,setUsername,fireSignup,fireLogin,phoneNo,setShowVerifyModal})=>{
  const onUsernameChange = useCallback((e)=>{
    setFormError('');
    setUsername(e.target.value)
  },[])

  const validateUsername = useCallback((e)=>{
    if (!username?.length) {
      // skip
      fireSignup(phoneNo,'');
    }
    else if (username?.length > 0 && username?.length < 4) {
      setFormError('Username must be at least 4 characters')
    }
    // TODO sanitize
    else if (username?.length > 4) {
      // valid username
      setFormError('');
      fireSignup(username);
      setShowVerifyModal(true);
    }
  },[username])

  const [formError,setFormError] = useState(''); 
  return (
    <>
    <Box className={`standard-modal ${styles.UsernameModal}`}>
      <Box className={`inner`}>
      <Typography variant="newUsername" component="p">
          Looks like you're new here!
        </Typography>
        <Typography variant="newUsername" component="p">
          Welcome!
        </Typography>
        <Typography variant="newUsername" component="p">
          Feel free to select a username.
        </Typography>
        <Typography variant="newUsernameOptional" component="p">
          (This is optional, and you can change it later, if you want.)
        </Typography>
        <TextField value={username} error={formError} onChange={onUsernameChange} label="Username" sx={{ background:'rgba(255,255,255,1)',marginTop:'20px',width:"100%",input:{color:"#000",width:"100%"},label:{color:"var(--mui-palette-UltraPlush-cyan) !important",top:'-0.8em'}}}/>
        <Button className={(username?.length)? (username?.length>=4)? styles.submitUsernameButton:styles.submitUsernameButtonDisabled:styles.submitUsernameButton} onClick={validateUsername}>{`${(username?.length)? (username?.length >=4)? 'Submit':'Must be at least 4 characters':'Skip'}` }</Button>
      </Box>
    </Box>
    </>
  )
}

export default UsernameForm