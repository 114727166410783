/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://55sphkf4drcmzkjw4u3evduozq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-4tmgics5uzgxpluhza4vrddnca",
    "aws_cognito_identity_pool_id": "us-west-2:2145e2b1-a7e0-439e-b82c-3bb5edc4b30b",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_AkBSDbp6m",
    "aws_user_pools_web_client_id": "1m2psojr4ih4qhs2afkbvcm96n",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "outworldsworlds173625-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
