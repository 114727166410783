import { Typography } from '@mui/material';
import styles from './styles.module.scss';

const ResetButton=({handler=()=>{}})=>{
  return (
    <>
      <button className={styles.ResetButton} onClick={handler}>
        <span>
          Discover More Outworlds
        </span>
      </button>
    </>
  )
}

export default ResetButton