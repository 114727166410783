import styles from './styles.module.scss';
import WorldMenuButton from './worldMenuButton';
import WorldMenuDrawer from './worldMenuDrawer';

const WorldMenu=({})=>{
  return (
    <>
      <WorldMenuButton />
      <WorldMenuDrawer />
    </>
  )
}

export default WorldMenu