import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material';
import styles from './styles.module.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useGlobalState from '../../Stores/globalStateManager';
import AuthGate from '../AuthGate'
import useUserState from '../../Stores/userStateManager';
import {maxUnAuthedVistaViews} from '../../Utils/authRules';
import {ReactComponent as LockIcon} from '../../assets/icons/noun-lock-6599527.svg'

const VistaAccordion=({vista,idx=0,currentlyOpenAccordion,setCurrentlyOpenAccordion})=>{
  const {currentVistaIdx,setCurrentVistaIdx,localWorld,setShowLoginTease} = useGlobalState((state)=>({currentVistaIdx:state.currentVistaIdx,setCurrentVistaIdx:state.setCurrentVistaIdx,localWorld:state.localWorld,setShowLoginTease:state.setShowLoginTease}))
  const {recentlyVisitedVistas} = useUserState((state)=>({recentlyVisitedVistas:state.recentlyVisitedVistas}))
  const currentVista = localWorld?.vistas?.items[idx];
  const VistaGuid = currentVista?.id

  return (
    <>
      <Accordion
        // singular accordion breaks accordion open/close anims
        // expanded={currentlyOpenAccordion===idx}
        // onChange={()=>{setCurrentlyOpenAccordion(idx)}} 
      >
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${idx+1}-content`}
          id={`panel${idx+1}-header`}
        >
          <Typography variant="DrawerAccordionSummery">
            {`Vista ${idx+1}`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {/* prompt */}
          <Typography variant="DrawerAccordionVistaDescription">
            {vista.prompt}
          </Typography>
          {/* button */}
          {currentVistaIdx === idx &&
            <Button variant='contained' disabled>Currently Viewing</Button>
          }
          {currentVistaIdx !== idx &&
          <AuthGate 
            authedComponent={
              <Button className={styles.viewButton} variant='contained' onClick={()=>{setCurrentVistaIdx(idx)}}>View</Button>
            }
            unAuthedComponent={<>
              {!!((recentlyVisitedVistas?.length >= maxUnAuthedVistaViews) && (recentlyVisitedVistas.indexOf(VistaGuid) === -1)) &&
              <Button className={styles.viewLockedButton} variant='contained' onClick={()=>{setShowLoginTease(true)}}><LockIcon />Login to View</Button>}
              {!((recentlyVisitedVistas?.length >= maxUnAuthedVistaViews) && (recentlyVisitedVistas.indexOf(VistaGuid) === -1)) &&
              <Button className={styles.viewButton} variant='contained' onClick={()=>{setCurrentVistaIdx(idx)}}>View</Button>}
              </>}
          />
          }

          
        </AccordionDetails>
        
      </Accordion>

    </>
  )
}

export default VistaAccordion