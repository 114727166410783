import styles from './styles.module.scss';
import AuthGate from '../AuthGate'
import {ReactComponent as LockIcon} from '../../assets/icons/noun-lock-6599527.svg'
import { Button } from '@mui/material';
import useGlobalState from '../../Stores/globalStateManager';
import ConfirmTerraform from '../Modals/misc/ConfirmTerraform';
import stardustPhial from '../../assets/img/stardustPhialIcon1.webp'
const TerraformButton=({confirmTerraform})=>{
  const {setShowLoginTease,setStandardModals} = useGlobalState((state)=>({setShowLoginTease:state.setShowLoginTease,setStandardModals:state.setStandardModals}))
  return (
    <>
      <AuthGate 
        // authedComponent={ /* TODO implement currency check */
        //   <Button className={styles.TerraformButton} variant='contained' onClick={()=>{setStandardModals(<ConfirmTerraform doTerraform={doTerraform}/>)}}>Terraform</Button>
        // }
        authedComponent={ /* TODO implement currency check */
          <Button className={styles.TerraformButton} variant='contained' onClick={()=>{confirmTerraform()}}>Terraform <span>({' '}10 <img src={stardustPhial} />{' '})</span></Button>
        }

        unAuthedComponent={
          <Button className={styles.TerraformButtonLocked} variant='contained' onClick={()=>{setShowLoginTease(true)}}><LockIcon />Login to Terraform</Button>
        }
      />
    </>
  )
}

export default TerraformButton