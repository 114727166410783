import { Box, Button, TextField, Typography } from '@mui/material';
import styles from './styles.module.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import useAuthState from '../../../Stores/authStateManager';
import { useNavigate } from 'react-router-dom';
import useGlobalState from '../../../Stores/globalStateManager';

const VerifyCodeForm=({phoneNo})=>{
  const [userEnteredCode,setUserEnteredCode] = useState('');
  const [resendLocked,setResendLocked] = useState(true);
  const [counterLastUpdated,setCounterLastUpdated] = useState(0)
  const countdownTimerRef = useRef(60);
  const countdownTimerIntervalRef = useRef();

  const {resendVerificationCode,verifyAuth} = useAuthState((state)=>({resendVerificationCode:state.resendVerificationCode,verifyAuth:state.verifyAuth}))
  const {setShowLoginFlow} = useGlobalState((state)=>({setShowLoginFlow:state.setShowLoginFlow}))

  const kickoffTimer = useCallback(()=>{
    clearInterval(countdownTimerIntervalRef.current);
    countdownTimerIntervalRef.current = setInterval(() => {
      console.log('timer')
      if (countdownTimerRef.current === 0) {
        clearInterval(countdownTimerIntervalRef.current);
        setResendLocked(false);
      }
      countdownTimerRef.current = countdownTimerRef.current - 1;
      setCounterLastUpdated(Date.now())
    }, 1000);
  },[])

  const resendCode = useCallback(()=>{
    if (resendLocked) return
    if (!phoneNo) return;
    const doResend = async ()=>{
      await resendVerificationCode(phoneNo)
      countdownTimerRef.current = 60;
      setResendLocked(true);
      kickoffTimer();
    }
    doResend();
  },[phoneNo,resendLocked])

  useEffect(()=>{
    kickoffTimer();
  },[])

  const updateUserEnteredCode = useCallback((e)=>{
    setUserEnteredCode(e.target.value);
  },[])

  const validateCode = useCallback(()=>{
    const doValidate = async ()=>{
      return await verifyAuth(userEnteredCode)
    }
    const codeIsValid = doValidate();
    if (codeIsValid) {
      setShowLoginFlow(false)
    }
  },[phoneNo,userEnteredCode])
  
  return (
    <Box className={`standard-modal ${styles.VerifyCodeForm}`}>
      <Box className={`inner`}>
        <Typography variant="loginFormHeader">
          Verify your code:
        </Typography>
        <TextField value={userEnteredCode}  onChange={updateUserEnteredCode} label="Verification Code" sx={{ background:'rgba(255,255,255,1)',marginTop:'40px', width:'100%',input:{color:"#000",width: '100%'},label:{color:'var(--mui-palette-UltraPlush-cyan) !important',top:'-0.8em'}}}/>
        <Button variant="contained" className={styles.submitCodeButton} onClick={validateCode}>Verify</Button>
        <Button variant="contained" className={styles.resendCodebutton} onClick={resendCode} disabled={!!resendLocked}>Resend Code {(resendLocked)? `(${countdownTimerRef.current}s)`:''}</Button>
      </Box>
    </Box>
  )
}

export default VerifyCodeForm