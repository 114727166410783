import { Box, Paper, Typography } from '@mui/material';
import BasePageTemplate from '../Templates/BasePageTemplate';
import styles from './styles.module.scss';
import WorldPromptInput from '../../Components/WorldPrompt/worldPromptInput';
import WorldPrompt from '../../Components/WorldPrompt';
import FloatingSubheader from '../../Components/FloatingSubheader';
import FloatingHeader from '../../Components/FloatingHeader';
import BaseWorldThumbnail from '../../Components/BaseWorldThumbnail';

import MechanicalForestsThumb from '../../assets/baseWorldThumbs/BW00-Mechanical-Forests.jpg';
import SafarisThumb from '../../assets/baseWorldThumbs/BW01-Safaris.jpg';
import CitiesThumb from '../../assets/baseWorldThumbs/BW02-Cities.jpg';
import CyberTemplesThumb from '../../assets/baseWorldThumbs/BW03-Cyber-Temples.jpg';
import DolphinCavesThumb from '../../assets/baseWorldThumbs/BW04-Dolphin-Caves.jpg';
import MysticalKingdomsThumb from '../../assets/baseWorldThumbs/BW05-Mystical-Kingdoms.jpg';
import DungeonsThumb from '../../assets/baseWorldThumbs/BW06-Dungeons.jpg';
import TheBackroomsThumb from '../../assets/baseWorldThumbs/BW07-The-Backrooms.jpg';
import TheCrawlspacesThumb from '../../assets/baseWorldThumbs/BW08-The-Crawlspaces.jpg';
import TheMarketsThumb from '../../assets/baseWorldThumbs/BW09-The-Markets.jpg';
import TestKitchensThumb from '../../assets/baseWorldThumbs/BW10-Test-Kitchens.jpg';
import FloatingBackButton from '../../Components/FloatingBackButton';
import { useTheme } from '@emotion/react';

const ExplorePage=({})=>{
  const theme = useTheme();
  return (
      <Box className={styles.ExplorePage}>
        <FloatingHeader/>
        <FloatingSubheader>
          <Typography variant="floatingSubheaderDefault">Explore</Typography>
        </FloatingSubheader>

        <Box className={styles.body}>
          <FloatingBackButton />
          <BaseWorldThumbnail worldName="Mechanical Forests" worldGuid='BW00-Mechanical-Forests' thumb={MechanicalForestsThumb} />
          <BaseWorldThumbnail worldName="Safaris" worldGuid='BW01-Safaris' thumb={SafarisThumb} />
          <BaseWorldThumbnail worldName="Cities" worldGuid='BW02-Cities' thumb={CitiesThumb} />
          <BaseWorldThumbnail worldName="Cyber Temples" worldGuid='BW03-Cyber-Temples' thumb={CyberTemplesThumb} />
          <BaseWorldThumbnail worldName="Dolphin Caves" worldGuid='BW04-Dolphin-Caves' thumb={DolphinCavesThumb} />
          <BaseWorldThumbnail worldName="Mystical Kingdoms" worldGuid='BW05-Mystical-Kingdoms' thumb={MysticalKingdomsThumb} />
          <BaseWorldThumbnail worldName="Dungeons" worldGuid='BW06-Dungeons' thumb={DungeonsThumb} />
          <BaseWorldThumbnail worldName="The Backrooms" worldGuid='BW07-The-Backrooms' thumb={TheBackroomsThumb} />
          <BaseWorldThumbnail worldName="The Crawlspaces" worldGuid='BW08-The-Crawlspaces' thumb={TheCrawlspacesThumb} />
          <BaseWorldThumbnail worldName="The Markets" worldGuid='BW09-The-Markets' thumb={TheMarketsThumb} />
          <BaseWorldThumbnail worldName="Test Kitchens" worldGuid='BW10-Test-Kitchens' thumb={TestKitchensThumb} sx={{
            [theme.breakpoints.up('lg')]: {
              gridColumn:3
            }
            
            }}/>
        </Box>
      </Box>
  )
}

export default ExplorePage