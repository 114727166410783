import logo from './logo.svg';
import './App.css';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import theme from './theme';
import HomePage from './Pages/HomePage';
import ExplorePage from './Pages/ExplorePage';
import WorldView from './Pages/WorldView';
import ModalResolver from './Components/ModalResolver';
import useGlobalState from './Stores/globalStateManager';
import { useEffect } from 'react';
import RequestingTerraform from './Components/Modals/TerraformModal/subComponents/requestingTerraform';
import TerraformInProgress from './Components/Modals/TerraformModal/subComponents/terraformInProgress';
import BaseWorldList from './Components/BaseWorldList';
import SVGSplit from './Components/SVGSplit';
import CreatePage from './Pages/CreatePage';
import BasePages from './Pages/BasePages';
import useAuthState from './Stores/authStateManager';
import AuthGate from './Components/AuthGate';
import SubscriptionDebug from './Components/SubscriptionDebug';
import MusicPlayer from './Components/MusicPlayer';

function App() {
  const {setTerraformModals,setShowLoginFlow} = useGlobalState((state)=>({setTerraformModals:state.setTerraformModals,setShowLoginFlow:state.setShowLoginFlow}))
  const {doLogout} = useAuthState((state)=>({doLogout:state.doLogout}));
  useEffect(()=>{
    window.doLogout = doLogout;
    window.setShowLoginFlow = setShowLoginFlow; // TODO DELME SEC
  },[doLogout])
  // TODO DELME
  // useEffect(()=>{
  //   setTerraformModals([<TerraformInProgress />])
  // },[])
  return (
    <>
      <CssVarsProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path={`${process.env.PUBLIC_URL}/worldView/:worldGuidParam`} element={<WorldView />}/>
          <Route path={`${process.env.PUBLIC_URL}/baseWorldsTest`} element={<BaseWorldList />} />
          <Route path={`${process.env.PUBLIC_URL}/:basePage`} element={<BasePages />}/>
          <Route path={`${process.env.PUBLIC_URL}/subscriptionTest`} element={<SubscriptionDebug />} />
          <Route path={`${process.env.PUBLIC_URL}/authGateTest`} element={<>
            <AuthGate authedComponent={<h1>AUTHED</h1>} unAuthedComponent={<h1>UNAUTHED</h1>} />
          </>} />
          <Route path={`${process.env.PUBLIC_URL}/`} element={<BasePages />}/>
          
          {/* <Route path="/SVGTest" element={<SVGSplit />} /> */}
        </Routes>
        <MusicPlayer />
        <ModalResolver />
      </CssVarsProvider>

    </>
  );
}

export default App;
