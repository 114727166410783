import { Box, Typography } from '@mui/material';
import styles from './styles.module.scss';
import defaultThumb from '../../assets/baseWorldThumbs/BW00-Mechanical-Forests.jpg'
import {ReactComponent as LockIcon} from '../../assets/icons/noun-lock-6599527.svg'
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import useGlobalState from '../../Stores/globalStateManager';
const UnAuthedBaseWorldThumbnail=({thumb=defaultThumb,worldName="Unnamed World",worldGuid="BWOO-Mechanical-Forests",sx={}})=>{
  const {setShowLoginTease} = useGlobalState((state)=>({setShowLoginTease:state.setShowLoginTease}))
  const navTo = useNavigate();
  const handleClick = useCallback(()=>{
    setShowLoginTease(true);
  },[])
  return (
    <Box className={styles.BaseWorldThumbnail} onClick={handleClick} sx={sx}>
      <Box className={styles.inner}>
        <Box className={styles.lockOverlay}>
          <LockIcon />
        </Box>
        <img src={thumb} />
        <Typography variant="baseWorldThumbnail" sx={{opacity: 0.3}}>{worldName}</Typography>
      </Box>
    </Box>
  )
}

export default UnAuthedBaseWorldThumbnail