import { Box, Modal } from '@mui/material';
import styles from './styles.module.scss';
import useGlobalState from '../../../Stores/globalStateManager';

const StandardModal=({})=>{
  const {standardModals,popStandardModals} = useGlobalState((state)=>({standardModals:state.standardModals,popStandardModals:state.popStandardModals}))
  return (
    <Modal
      open={true}
      onClose={popStandardModals}
    >
      <Box className={`standard-modal ${styles.StandardModal}`}>
        <Box className={`inner ${styles.inner}`}>
          {standardModals[0]}
        </Box>
      </Box>
    </Modal>
  )
}

export default StandardModal