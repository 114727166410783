/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const terraformOutworld = /* GraphQL */ `
  mutation TerraformOutworld($worldName: String, $worldPrompt: String!) {
    terraformOutworld(worldName: $worldName, worldPrompt: $worldPrompt)
  }
`;
export const createVista = /* GraphQL */ `
  mutation CreateVista(
    $input: CreateVistaInput!
    $condition: ModelVistaConditionInput
  ) {
    createVista(input: $input, condition: $condition) {
      id
      name
      url
      prompt
      world {
        id
        name
        description
        rootUrl
        status
        createdBy
        worldType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      worldVistasId
      __typename
    }
  }
`;
export const updateVista = /* GraphQL */ `
  mutation UpdateVista(
    $input: UpdateVistaInput!
    $condition: ModelVistaConditionInput
  ) {
    updateVista(input: $input, condition: $condition) {
      id
      name
      url
      prompt
      world {
        id
        name
        description
        rootUrl
        status
        createdBy
        worldType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      worldVistasId
      __typename
    }
  }
`;
export const deleteVista = /* GraphQL */ `
  mutation DeleteVista(
    $input: DeleteVistaInput!
    $condition: ModelVistaConditionInput
  ) {
    deleteVista(input: $input, condition: $condition) {
      id
      name
      url
      prompt
      world {
        id
        name
        description
        rootUrl
        status
        createdBy
        worldType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      worldVistasId
      __typename
    }
  }
`;
export const createWorldPhoto = /* GraphQL */ `
  mutation CreateWorldPhoto(
    $input: CreateWorldPhotoInput!
    $condition: ModelWorldPhotoConditionInput
  ) {
    createWorldPhoto(input: $input, condition: $condition) {
      id
      name
      url
      world {
        id
        name
        description
        rootUrl
        status
        createdBy
        worldType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      worldPhotosId
      __typename
    }
  }
`;
export const updateWorldPhoto = /* GraphQL */ `
  mutation UpdateWorldPhoto(
    $input: UpdateWorldPhotoInput!
    $condition: ModelWorldPhotoConditionInput
  ) {
    updateWorldPhoto(input: $input, condition: $condition) {
      id
      name
      url
      world {
        id
        name
        description
        rootUrl
        status
        createdBy
        worldType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      worldPhotosId
      __typename
    }
  }
`;
export const deleteWorldPhoto = /* GraphQL */ `
  mutation DeleteWorldPhoto(
    $input: DeleteWorldPhotoInput!
    $condition: ModelWorldPhotoConditionInput
  ) {
    deleteWorldPhoto(input: $input, condition: $condition) {
      id
      name
      url
      world {
        id
        name
        description
        rootUrl
        status
        createdBy
        worldType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      worldPhotosId
      __typename
    }
  }
`;
export const createWorldSnippet = /* GraphQL */ `
  mutation CreateWorldSnippet(
    $input: CreateWorldSnippetInput!
    $condition: ModelWorldSnippetConditionInput
  ) {
    createWorldSnippet(input: $input, condition: $condition) {
      id
      content
      attribution
      world {
        id
        name
        description
        rootUrl
        status
        createdBy
        worldType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      worldSnippetsId
      __typename
    }
  }
`;
export const updateWorldSnippet = /* GraphQL */ `
  mutation UpdateWorldSnippet(
    $input: UpdateWorldSnippetInput!
    $condition: ModelWorldSnippetConditionInput
  ) {
    updateWorldSnippet(input: $input, condition: $condition) {
      id
      content
      attribution
      world {
        id
        name
        description
        rootUrl
        status
        createdBy
        worldType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      worldSnippetsId
      __typename
    }
  }
`;
export const deleteWorldSnippet = /* GraphQL */ `
  mutation DeleteWorldSnippet(
    $input: DeleteWorldSnippetInput!
    $condition: ModelWorldSnippetConditionInput
  ) {
    deleteWorldSnippet(input: $input, condition: $condition) {
      id
      content
      attribution
      world {
        id
        name
        description
        rootUrl
        status
        createdBy
        worldType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      worldSnippetsId
      __typename
    }
  }
`;
export const createWorld = /* GraphQL */ `
  mutation CreateWorld(
    $input: CreateWorldInput!
    $condition: ModelWorldConditionInput
  ) {
    createWorld(input: $input, condition: $condition) {
      id
      name
      description
      vistas {
        nextToken
        __typename
      }
      photos {
        nextToken
        __typename
      }
      snippets {
        nextToken
        __typename
      }
      rootUrl
      status
      createdBy
      worldType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWorld = /* GraphQL */ `
  mutation UpdateWorld(
    $input: UpdateWorldInput!
    $condition: ModelWorldConditionInput
  ) {
    updateWorld(input: $input, condition: $condition) {
      id
      name
      description
      vistas {
        nextToken
        __typename
      }
      photos {
        nextToken
        __typename
      }
      snippets {
        nextToken
        __typename
      }
      rootUrl
      status
      createdBy
      worldType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWorld = /* GraphQL */ `
  mutation DeleteWorld(
    $input: DeleteWorldInput!
    $condition: ModelWorldConditionInput
  ) {
    deleteWorld(input: $input, condition: $condition) {
      id
      name
      description
      vistas {
        nextToken
        __typename
      }
      photos {
        nextToken
        __typename
      }
      snippets {
        nextToken
        __typename
      }
      rootUrl
      status
      createdBy
      worldType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      preferred_username
      phone_number
      currencyStore {
        id
        qty
        createdAt
        updatedAt
        userCurrencyStoreUserId
        __typename
      }
      createdAt
      updatedAt
      userCurrencyStoreId
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      preferred_username
      phone_number
      currencyStore {
        id
        qty
        createdAt
        updatedAt
        userCurrencyStoreUserId
        __typename
      }
      createdAt
      updatedAt
      userCurrencyStoreId
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      preferred_username
      phone_number
      currencyStore {
        id
        qty
        createdAt
        updatedAt
        userCurrencyStoreUserId
        __typename
      }
      createdAt
      updatedAt
      userCurrencyStoreId
      __typename
    }
  }
`;
export const createUserCurrencyStore = /* GraphQL */ `
  mutation CreateUserCurrencyStore(
    $input: CreateUserCurrencyStoreInput!
    $condition: ModelUserCurrencyStoreConditionInput
  ) {
    createUserCurrencyStore(input: $input, condition: $condition) {
      id
      user {
        id
        preferred_username
        phone_number
        createdAt
        updatedAt
        userCurrencyStoreId
        __typename
      }
      qty
      createdAt
      updatedAt
      userCurrencyStoreUserId
      __typename
    }
  }
`;
export const updateUserCurrencyStore = /* GraphQL */ `
  mutation UpdateUserCurrencyStore(
    $input: UpdateUserCurrencyStoreInput!
    $condition: ModelUserCurrencyStoreConditionInput
  ) {
    updateUserCurrencyStore(input: $input, condition: $condition) {
      id
      user {
        id
        preferred_username
        phone_number
        createdAt
        updatedAt
        userCurrencyStoreId
        __typename
      }
      qty
      createdAt
      updatedAt
      userCurrencyStoreUserId
      __typename
    }
  }
`;
export const deleteUserCurrencyStore = /* GraphQL */ `
  mutation DeleteUserCurrencyStore(
    $input: DeleteUserCurrencyStoreInput!
    $condition: ModelUserCurrencyStoreConditionInput
  ) {
    deleteUserCurrencyStore(input: $input, condition: $condition) {
      id
      user {
        id
        preferred_username
        phone_number
        createdAt
        updatedAt
        userCurrencyStoreId
        __typename
      }
      qty
      createdAt
      updatedAt
      userCurrencyStoreUserId
      __typename
    }
  }
`;
