import { CameraControls } from '@react-three/drei';
import {create} from 'zustand';
import { subscribeWithSelector, persist, createJSONStorage } from 'zustand/middleware'
import useUserState from './userStateManager';
// import RequestingTerraform from '../Components/Modals/TerraformModal/subComponents/requestingTerraform';
// import {v4 as uuidV4} from 'uuid'

const worldPromptMaxLengthChars = 2000;
const worldNameMaxLengthChars = 20;

const useGlobalState = create(
  persist(subscribeWithSelector((set,get)=>({
    worldPromptMaxLengthChars: worldPromptMaxLengthChars,
    worldPrompt:"A shiny utopian cityscape of gleaming chrome and glass",
    setWorldPrompt: (newWorldPrompt)=>{
      // console.log('newWorldPrompt',newWorldPrompt)
      const _newPrompt = newWorldPrompt.substr(0,worldPromptMaxLengthChars)
      set({worldPrompt:_newPrompt})
    },
    worldNameMaxLengthChars: worldNameMaxLengthChars,
    worldName: '',
    setWorldName: (newWorldName)=>{
      // console.log('newWorldName',newWorldName)
      const _newName = newWorldName.substr(0,worldNameMaxLengthChars)
      set({worldName:_newName})
    },
    localWorld: null,
    setLocalWorld: (newWorld)=>{
      console.log('useUserState.getState().addToRecentlyVisitedWorlds',useUserState.getState().addToRecentlyVisitedWorlds)
      const userState = useUserState.getState()
      console.log('userState is',userState)
      // const _addToRecentWorlds = userState.addToRecentlyVisitedWorlds;
      // console.log('_addToRecentWorlds',_addToRecentWorlds)
      if (userState.recentlyVisitedWorlds.indexOf(newWorld.id) === -1) {
        userState.addToRecentlyVisitedWorlds(newWorld.id)
      }
      set({localWorld:newWorld})
    },
    worldMenuOpen: false,
    setWorldMenuOpen: (newState)=>{
      set({worldMenuOpen:newState})
    },
    toggleWorldMenuOpen: ()=>{
      const _currState = get().worldMenuOpen;
      set({worldMenuOpen:!_currState});
    },
    currentVistaIdx: 0,
    setCurrentVistaIdx: (newIdx)=>{
      const userState = useUserState.getState()
      const vistaId = get()?.localWorld?.vistas?.items[newIdx].id
      if (!!vistaId && userState?.recentlyVisitedVistas.indexOf(vistaId) === -1) {
        userState.addToRecentlyVisitedVistas(vistaId)
      }
      set({currentVistaIdx:newIdx})
    },
    resetCurrentVistaIdx:()=>{
      set({currentVistaIdx:0})
    },
    terraformModals: [],
    setTerraformModals: (newModals)=>{
      if (Array.isArray(newModals)) {
        set({terraformModals:newModals})
      }
      else {
        set({terraformModals:[newModals]})
      }
    },
    addToTerraformModals: (itemsToAdd)=>{
      const _Tmodals = get().terraformModals;
      if (Array.isArray(itemsToAdd)){
        _Tmodals.push(...itemsToAdd);
      }
      else {
        _Tmodals.push(itemsToAdd);
      }
      set({terraformModalsModals:_Tmodals})
    },
    removeFromTerraformModalsByIdx: (index)=>{
      const _Tmodals = get().terraformModals;
      _Tmodals.splice(index,1);
      set({terraformModals:_Tmodals})
    },
    popTerraformModals: ()=>{
      const _Tmodals = get().terraformModals;
      _Tmodals.pop();
      set({terraformModals:_Tmodals})
    },
    scanReports: ["A traveler stumbles upon a vibrant marketplace in the heart of the magical kingdom, where talking animals barter and trade under the glow of iridescent crystals. The scent of exotic spices fills the air, mingling with the chatter of merchants and the fluttering of colorful bird-like creatures that flit between stalls.","In the enchanted forest of the kingdom, a moss-covered path winds through towering trees that whisper ancient secrets to those who listen. Beams of golden sunlight filter through the canopy above, casting dappled shadows on the forest floor where luminous fireflies dance in a mesmerizing display of light and color.","At the edge of a sparkling lake, a grand castle looms, its turrets reaching towards the clouds. As the sun sets, the castle's walls seem to shimmer with a faint ethereal glow, while graceful swans glide across the water and magical creatures gather on the banks to watch the spectacle unfold."],
    setScanReports: (newScanReports)=>{
      set({scanReports:newScanReports})
    },
    controlTypeSelection: null,
    setControlTypeSelection: (newControlType)=>{
      set({controlTypeSelection:newControlType});
    },
    vistaIsLoading: false,
    setVistaIsLoading: (newState)=>{
      set({vistaIsLoading:newState})
    },
    isMotionAvailable: false,
    setIsMotionAvailable: (newState)=>{
      set({isMotionAvailable:newState})
    },
    showLoginFlow: false,
    setShowLoginFlow: (newState)=>{
      set({showLoginFlow:newState})
    },
    showLoginTease: false,
    setShowLoginTease: (newState)=>{
      set({showLoginTease:newState})
    },
    standardModals: [],
    addToStandardModals: (newContent)=>{
      const _modals = get().standardModals;
      _modals.push(newContent);
      set({standardModals:_modals});
    },
    setStandardModals: (newModals)=>{
      if (Array.isArray(newModals)) {
        set({standardModals:newModals})
      }
      else {
        set({standardModals:[newModals]})
      }
    },
    popStandardModals: ()=>{
      const _modals = get().standardModals;
      _modals.pop();
      set({standardModals:_modals});
    },
    removeFromStandardModalsByIdx: (index)=>{
      const _modals = get().standardModals;
      _modals.splice(index,1);
      set({standardModals:_modals})
    },
    clearStandardModals: ()=>{
      const _modals = []
      set({standardModals:_modals});
    },
    mainMenuOpen: false,
    setMainMenuOpen: (newState)=>{
      set({mainMenuOpen:newState})
    }
  })),
  {
    name: 'Outworlds-GSM',
    storage: createJSONStorage(()=>localStorage)
  }
  )
)

export default useGlobalState