import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from '../styles.module.scss';
import { Typography } from '@mui/material';

const TerraformCountdownTimer=({mins=1,secs=15})=>{
  const _secs = useMemo(()=>{
    return secs%60;
  },[mins,secs])
  const _mins = useMemo(()=>{
    return mins + (Math.floor(secs/60));
  },[mins,secs])

  const [countdownM,setCountdownM] = useState(_mins);
  const [countdownS,setCountdownS] = useState(_secs);

  const doCountdown = useCallback((startM,startS)=>{
    let mins = startM,
        secs = startS;

    let countdownInterval;
    const subtractSecond = ()=>{
      if (secs === 0) {
        if (mins === 0) {
          // clearInterval(countdownInterval);
          return
        }
        mins--;
        secs = 59;
      }
      else {
        secs--;
      }
      setCountdownM(mins);
      setCountdownS(secs);
    }

    countdownInterval = setInterval(()=>{
      subtractSecond()
    },1000)
  },[])

  useEffect(()=>{
    if (!_mins && !_secs){return}
    doCountdown(_mins,_secs)
  },[_mins,_secs]);

  return (
    <>
      
        {(countdownM > 0 || countdownS > 0) &&
          <Typography variant="TerraformCountdownTimer">
            {countdownM}:{(countdownS < 10)? `0${countdownS}`:countdownS}
          </Typography>
        }
        {(countdownM <= 0 && countdownS <= 0) &&
          <Typography variant="TerraformCountdownTimerOvertime">
            Standby...
          </Typography> 
        }
        
        
    </>
  )
}

export default TerraformCountdownTimer