import { CameraControls, DeviceOrientationControls, TrackballControls } from '@react-three/drei';
import useGlobalState from '../../Stores/globalStateManager';
import { useEffect } from 'react';
import OrientationCamera from '../OrientationCamera';
import DragCamera from '../DragCamera';
const ConditionalControls=({})=>{
  const {controlTypeSelection,setControlTypeSelection,orientationPermsDenied,setOrientationPermsDenied,isMotionAvailable,setIsMotionAvailable} = useGlobalState((state)=>({controlTypeSelection:state.controlTypeSelection,setControlTypeSelection:state.setControlTypeSelection,orientationPermsDenied:state.orientationsPermsDenied, setOrientationPermsDenied:state.setOrientationPermsDenied,setIsMotionAvailable:state.setIsMotionAvailable,isMotionAvailable:state.isMotionAvailable}))

  useEffect(()=>{
    // default to camera controls and only switch to orientation if perms are available
    if (typeof DeviceMotionEvent  !== "undefined") {
      if (typeof DeviceMotionEvent.requestPermission  === "function") {
        DeviceMotionEvent.requestPermission().then((resp)=>{
          if (resp === 'granted') {
            setIsMotionAvailable(true);
            setOrientationPermsDenied(true);
            if (!controlTypeSelection) setControlTypeSelection('orientation')
          }
        })
        .catch((err)=>{
          if (err?.indexOf('denied')) {
            if (!controlTypeSelection) setControlTypeSelection('camera')
            setIsMotionAvailable(true);
            setOrientationPermsDenied(true);
          }
          else {
            console.log(err);
          }
        }) 
      }
      else if (!!('ontouchstart' in window)) { // best way we have to detect actual orientation devices for now
        setIsMotionAvailable(true)
        if (!controlTypeSelection) setControlTypeSelection('orientation')
      }
      else {
        setIsMotionAvailable(false)
        if (!controlTypeSelection) setControlTypeSelection('drag')
      }
    }
  },[])
  
  return (
    <>
      {!!(controlTypeSelection === 'drag') && <CameraControls />}
      {!!(controlTypeSelection === 'orientation') && <DeviceOrientationControls />}
      {!!(controlTypeSelection === 'camera') && !!(isMotionAvailable) && !(orientationPermsDenied) && <OrientationCamera />}
      {!!(controlTypeSelection === 'camera') && !!(!(isMotionAvailable) || !!(orientationPermsDenied)) && <DragCamera />}
    </> 
  )
}

export default ConditionalControls