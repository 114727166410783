import { Box, Button, Typography } from '@mui/material';
import styles from './styles.module.scss';
import { MuiTelInput } from 'mui-tel-input';

const LoginForm=({setShowUsernameModal,checkUserExists,phoneNo,onPhoneNoChange,fireLogin,setShowVerifyModal})=>{
  
  return (
    <>
      <Box className={`standard-modal ${styles.LoginModal}`}>
        <Box className={`inner ${styles.inner}`}>
          <Typography variant="loginFormHeader" component="h2">Login to Outworlds</Typography>
          <MuiTelInput sx={{ background:'rgba(255,255,255,1)',width:"100%",marginBottom:"20px",marginTop:"15px",input:{color:"#000",width:"100%"},label:{color:"var(--mui-palette-UltraPlush-cyan)",top:'-0.6em'}}} name="phonenumber" defaultCountry='US' label="Phone Number" value={phoneNo} onChange={onPhoneNoChange} />
          <Typography variant="loginSubheader" component="p" sx={{marginBottom:"10px"}}>Outworlds respects your privacy.</Typography>
          <Typography variant="loginSubheader" component="p">We will not:</Typography>
          <ul>
            <li>Spam</li>
            <li>Sell</li>
            <li>Share</li>
          </ul>
          <Typography variant="loginSubheader" component="p">your phone number.</Typography>
          <Typography variant="loginSubheader" component="p">We will only:</Typography>
          <ul>
            <li>Send you one-time passwords</li>
          </ul>
          <Typography variant="loginNotice">* Carrier messaging fees may apply</Typography>
          <Button variant="contained" className={styles.loginButton} onClick={async ()=>{
            const userExists = await checkUserExists(phoneNo);
            console.log('LoginForm userExists',userExists)
            if (!userExists) {
              setShowUsernameModal(true)
            }
            else {
              fireLogin()
              setShowVerifyModal(true);
            }
          }}>Login</Button>
          </Box>
      </Box> 
    </>
  )
}

export default LoginForm